import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import { capitalize } from 'lodash';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { DialogComponent } from '../../../components/basics/DialogComponent';
import { StyledTableRow } from '../../../components/basics/StyledTableRow';
import { HeadCell, Order, TableHeaderComponent } from '../../../components/basics/TableHeaderComponent';
import { TableToolbar } from '../../../components/basics/TableToolbarComponent';
import { PermissionsObj } from '../../../hooks/useCrudPermissions';
import { Attachment } from '../../../models/Attachment';
import { Company } from '../../../models/Company';
import { MonthNameEnum, SoldItemFile, StatusColorEnum, StatusValueEnum } from '../../../models/SoldItemFile';
import { User, getUserName } from '../../../models/User';
import { formatDateTime } from '../../../utils/utils';
import { useSoldItemFilePageContext } from '../context/SoldItemsContext';

export const headCells: readonly HeadCell[] = [
  {
    id: 'company',
    align: 'left',
    disablePadding: true,
    disableSorting: false,
    label: 'Empresa'
  },
  {
    id: 'owner',
    align: 'left',
    disablePadding: false,
    disableSorting: false,
    label: 'Usuário'
  },
  {
    id: 'record_year',
    align: 'left',
    disablePadding: false,
    disableSorting: false,
    label: 'Ano'
  },
  {
    id: 'record_month',
    align: 'left',
    disablePadding: false,
    disableSorting: false,
    label: 'Mês'
  },
  {
    id: 'ecd_file',
    align: 'left',
    disablePadding: false,
    disableSorting: true,
    label: 'Arquivo'
  },
  {
    id: 'status_label',
    align: 'left',
    disablePadding: false,
    disableSorting: true,
    label: 'Status'
  },
  {
    id: 'created_at',
    align: 'left',
    disablePadding: false,
    disableSorting: false,
    label: 'Criado em '
  },
  {
    id: 'start_at',
    align: 'left',
    disablePadding: false,
    disableSorting: false,
    label: 'Importação iniciada em'
  },
  {
    id: 'actions',
    align: 'right',
    disablePadding: false,
    disableSorting: true,
    label: 'Ações'
  }
];

export interface Props {
  onView: (item: SoldItemFile) => void;
  onEdit: (item: SoldItemFile) => void;
  permissions: PermissionsObj;
}

export const SoldItemsTable = ({ onView, onEdit, permissions }: Props) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof SoldItemFile>('created_at');
  const [selected, setSelected] = useState<string[]>([]);

  const [deleteItem, setDeleteItem] = useState<SoldItemFile | undefined>(undefined);
  const [deleteMany, setDeleteMany] = useState<boolean>(false);

  const {
    soldItemFile,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage,
    deleteSoldItemFile,
    deleteManySoldItemFile
  } = useSoldItemFilePageContext();

  const labelDisplayedRows = ({ from, to, count }: any) => {
    return `${from} - ${to} de ${count !== -1 ? count : `mais de ${to}`}`;
  };

  const handleDeleteItem = (item: SoldItemFile) => {
    setDeleteItem(item);
  };

  const handleDeleteSelectedItems = () => {
    setDeleteMany(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteItem?.id) {
      await deleteSoldItemFile(deleteItem.id);
    } else if (deleteMany) {
      await deleteManySoldItemFile(selected);
    }
    setDeleteMany(false);
    setDeleteItem(undefined);
    setSelected([]);
  };

  const handleDeleteCancel = () => {
    setDeleteMany(false);
    setDeleteItem(undefined);
  };

  const handleRequestSort = (property: keyof SoldItemFile) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleOrderChange(property, isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = soldItemFile?.results.map((item: SoldItemFile) => String(item.id)) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const isSelected = (id: number) => selected.indexOf(id.toString()) != -1;

  useEffect(() => {
    setSelected([]);
  }, [soldItemFile]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableToolbar numSelected={selected?.length} onDeleteSelected={handleDeleteSelectedItems} permissions={permissions} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
            <TableHeaderComponent
              headCells={headCells}
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={soldItemFile?.count || 0}
            />
            <TableBody>
              {soldItemFile && soldItemFile?.results?.length > 0 ? (
                <>
                  {soldItemFile?.results?.map((row: SoldItemFile, index: number) => {
                    const isItemSelected = isSelected(row?.id!);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        onClick={(event) => handleClick(event, String(row.id))}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox" align="left">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none" align="left">
                          {(row?.company as Company)?.company_name || ' - '}
                        </TableCell>
                        <TableCell align="left">{row?.owner ? getUserName(row.owner as User) : ' - '}</TableCell>
                        <TableCell align="left">{row.record_year}</TableCell>
                        <TableCell align="left">{MonthNameEnum[row.record_month]}</TableCell>
                        <TableCell align="left">
                          {row.sold_item_attachment ? (row.sold_item_attachment as Attachment).name : ' - '}
                        </TableCell>
                        <TableCell align="left">
                          <Alert severity={StatusColorEnum[row.status] || 'info'} variant="standard" sx={{ py: 0.2, borderRadius: 5 }}>
                            {capitalize(row.status_label)}
                            {row.sold_item_attachment && row.status === StatusValueEnum.COMPLETED}
                          </Alert>
                        </TableCell>
                        <TableCell align="left">{formatDateTime(row.created_at)}</TableCell>
                        <TableCell align="left">{row.start_at ? formatDateTime(row.start_at) : ' - '}</TableCell>
                        <TableCell align="right">
                          {permissions.hasReadPermission && (
                            <Tooltip title="Visualizar">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onView(row);
                                }}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {permissions.hasDeletePermission && (
                            <Tooltip title="Excluir">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteItem(row as SoldItemFile);
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : (
                <StyledTableRow>
                  <TableCell align="center" colSpan={headCells.length + 1}>
                    Nenhum registro encontrado
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {soldItemFile && soldItemFile?.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 25, 50]}
            component="div"
            count={soldItemFile?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={labelDisplayedRows}
          />
        )}
        {(deleteItem || deleteMany) && (
          <DialogComponent
            title={deleteItem ? 'Excluir Empresa' : 'Excluir Empresas'}
            description={deleteItem ? 'Tem certeza que deseja excluir este arquivo?' : 'Tem certeza que deseja excluir estes arquivos?'}
            onCancel={handleDeleteCancel}
            onConfirm={handleDeleteConfirm}
          />
        )}
      </Paper>
    </Box>
  );
};
