import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { Button, Checkbox, DialogContent, FormControlLabel, Popover, Stack } from '@mui/material';
import { useAnalyticsNCGTableContext } from '../../context/TableContext';

interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export const ColumnsPopover = ({ anchorEl, onClose }: Props) => {
  const { columns, toggleColumnVisibility } = useAnalyticsNCGTableContext();

  let columnsByYear = columns?.reduce((acc, column) => {
    if (!acc[column.year]) {
      acc[column.year] = [];
    }
    acc[column.year].push(column);
    return acc;
  }, {} as any);

  const handleToggleAll = (visible: boolean) => {
    columns.forEach((column: any) => {
      if (column.visible == visible) {
        toggleColumnVisibility(column.id);
      }
    });
  };

  const handleToggleAllByYear = (year: string, visible?: boolean) => {
    columnsByYear[year].forEach((column: any) => {
      if (column.visible == visible) {
        toggleColumnVisibility(column.id);
      }
    });
  };

  const checkByYear = (year: string) => {
    return columnsByYear[year]?.every((column: any) => column.visible);
  };

  const checkAll = () => {
    return columns.every((column: any) => column.visible);
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      sx={{ mt: 2 }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <DialogContent>
        <Stack spacing={2}>
          {Object.keys(columnsByYear)?.map((key: any, index: number) => {
            let columns = columnsByYear[key];
            return (
              <Stack spacing={2} key={index}>
                <Stack>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={checkByYear(key) ? <RadioButtonUnchecked /> : <CheckCircle />}
                    onClick={() => handleToggleAllByYear(key, checkByYear(key))}
                  >
                    {checkByYear(key) ? 'Desmarcar todos' : 'Marcar todos'} - {key}
                  </Button>
                </Stack>
                {columns?.map((column: any, index: number) => {
                  return (
                    <FormControlLabel
                      control={<Checkbox checked={column.visible} onChange={() => toggleColumnVisibility(column.id)} />}
                      label={column.name}
                      key={`${column.id}-${index}`}
                    />
                  );
                })}
              </Stack>
            );
          })}
        </Stack>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 2
          }}
          columnGap={1}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={checkAll() ? <RadioButtonUnchecked /> : <CheckCircle />}
            onClick={() => handleToggleAll(checkAll())}
          >
            {checkAll() ? 'Desmarcar todos' : 'Marcar todos'}
          </Button>
        </Stack>
      </DialogContent>
    </Popover>
  );
};
