export const ROUTES = {
  public: {
    login: '/login',
    signUp: '/cadastro',
    accountConfirmation: '/confirmacao-de-conta'
  },
  authenticated: {
    dashboard: {
      home: '/inicio'
    },
    user: {
      profile: '/perfil'
    },
    access: {
      users: '/usuarios',
      permissionGroups: '/perfis'
    },
    organizations: {
      companies: '/empresas',
      companiesGroups: '/grupos-empresas',
      companiesTypes: '/tipos-empresas'
    },
    settings: {
      dashboardStructure: '/estrutura-da-dashboard'
    },
    analytics: {
      analytics: '/analises',
      analyticType: '/tipo-analise',
      chartAccount: '/plano-contas'
    },
    accountingEntries: {
      spedImport: '/importacao-sped',
      manualInputs: '/lancamentos-manuais',
      soldItems: '/itens-vendidos',
      budget: '/orcamentos'
    },
    conciliation: {
      conciliation: '/conciliacao'
    },
    auxiliary: {
      parameters: '/parameters'
    }
  }
};
