import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Fragment } from 'react/jsx-runtime';
import { IAnalyticsDRERow } from '../../../../../../models/AnalyticsDRE';
import { TableRowRecursive } from '../TableRowRecursive';
import { useAnalyticsDRETableContext } from '../context/TableContext';
import './DynamicTable.css';

export default function DynamicTableDRE() {
  const { data, columns, toggleColumnVisibility, companyGroup, filterUseBudget } = useAnalyticsDRETableContext();

  return (
    <TableContainer style={{ overflow: 'auto', maxHeight: 'calc(100vh - 260px)' }}>
      <Table stickyHeader aria-label="sticky table" size="small">
        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
          <TableRow>
            {/* empty cell equivalente a descrição */}
            <TableCell key="empty-cell" />
            {columns.map(
              (column, index) =>
                column?.visible && (
                  <TableCell
                    key={`${column.id}-header-${index}`}
                    colSpan={column.id === 'description' ? 0 : column.type === 'average' ? 0 : filterUseBudget ? 3 : 2}
                  >
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="subtitle1" textAlign={column.id === 'description' ? 'left' : 'right'}>
                        {column.name}
                      </Typography>
                      <IconButton onClick={() => toggleColumnVisibility(column.id)}>
                        <RemoveCircleOutlineIcon sx={{ fontSize: 15 }} />
                      </IconButton>
                    </Stack>
                  </TableCell>
                )
            )}
          </TableRow>
          <TableRow>
            <TableCell key="description-empty-cell" rowSpan={2} />
            {columns.map(
              (column, index) =>
                column.visible &&
                (column.type == 'average' ? (
                  <Fragment key={`${column.id}-header-${index}`}>
                    <TableCell key={`${column.id}-header-value`} align="center">
                      <Typography variant="subtitle2">Média</Typography>
                    </TableCell>
                  </Fragment>
                ) : (
                  <Fragment key={`${column.id}-header-${index}`}>
                    {companyGroup.use_budget && filterUseBudget && (
                      <TableCell key={`${column.id}-budget-${index}`} align="right">
                        <Typography variant="subtitle2">Orçamento</Typography>
                      </TableCell>
                    )}
                    <TableCell key={`${column.id}-value-${index}`} align="right">
                      <Typography variant="subtitle2">Valor</Typography>
                    </TableCell>
                    <TableCell key={`${column.id}-percentual-${index}`} align="right">
                      <Typography variant="subtitle2">AV</Typography>
                    </TableCell>
                  </Fragment>
                ))
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.report?.map((row: IAnalyticsDRERow, index: number) => (
            <TableRowRecursive key={`row-${row.id}-${row.description}-${row.level}-${index}`} row={row} level={row.level} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
