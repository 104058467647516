import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SelectOption } from '../../../../components/basics/ControlledComboBox';
import { DialogComponent } from '../../../../components/basics/DialogComponent';
import { StyledTableRow } from '../../../../components/basics/StyledTableRow';
import { Analytics } from '../../../../models/Analytics';
import { AnalyticsAccounts } from '../../../../models/AnalyticsAccounts';
import { ChartAccounts } from '../../../../models/ChartAccounts';
import { AnalyticsService } from '../../../../services/Analytics.service';
import { AnalyticsAccountService } from '../../../../services/AnalyticsAccount.service';
import { hideSpinner, showSpinner } from '../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../store/slicers/snackbarAlert.slicer';
import { AnalyticsAccountsFormDialog } from './AnalyticsAccountsFormDialog';
import { ReplicateDialogComponent } from './ReplicateDialogComponent';

interface AnalyticsAccountsTabProps {
  item: ChartAccounts;
}

export const ChartAccountConfigurationTab = ({ item }: AnalyticsAccountsTabProps) => {
  const dispatch = useDispatch();
  const [editedAccounts, setEditedAccounts] = useState<Record<number, Analytics>>({});
  const [analyticsAccounts, setAnalyticsAccounts] = useState<AnalyticsAccounts[]>([]);
  const [analyticsAccountsParents, setAnalyticsAccountsParents] = useState<SelectOption[]>([]);
  const [analyticsBP, setAnalyticsBP] = useState<Analytics[]>([]);
  const [analyticsDRE, setAnalyticsDRE] = useState<Analytics[]>([]);
  const [analyticsNcg, setAnalyticsNcg] = useState<Analytics[]>([]);
  const [analyticsFc, setAnalyticsFc] = useState<Analytics[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [countPagination, setCountPagination] = React.useState<number>(0);
  const [selectedBPMap, setSelectedBPMap] = useState<Record<number, string>>({});
  const [selectedDREMap, setSelectedDREMap] = useState<Record<number, string>>({});
  const [selectedNcgMap, setSelectedNcgMap] = useState<Record<number, string>>({});
  const [selectedFcMap, setSelectedFcMap] = useState<Record<number, string>>({});
  const [newAccount, setNewAccount] = useState(false);
  const [removeAccount, setRemoveAccount] = useState<AnalyticsAccounts | undefined>(undefined);
  const [replicateAccount, setReplicateAccount] = useState<AnalyticsAccounts | undefined>(undefined);
  const [saved, setSaved] = useState(0);
  const [bpIndexes, setBPIndexes] = useState<Record<number, string>>({});
  const [dreIndexes, setDREIndexes] = useState<Record<number, string>>({});
  const [ncgIndexes, setNcgIndexes] = useState<Record<number, string>>({});
  const [fcIndexes, setFcIndexes] = useState<Record<number, string>>({});
  const [page, setPage] = React.useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);

  const handleNewAccount = async () => {
    dispatch(showSpinner());
    await fetchAccountsParents();
    dispatch(hideSpinner());
    
    setNewAccount(true);
  };

  const handleCloseDialogs = () => {
    setNewAccount(false);
  };

  const updateSaved = () => {
    setSaved((prevSaved) => (!prevSaved ? 1 : 0));
  };

  const updateEditedAccounts = (index, newData) => {
    setEditedAccounts((prevEditedAccounts) => ({
      ...prevEditedAccounts,
      [index]: {
        ...prevEditedAccounts[index],
        ...newData
      }
    }));
  };

  const handleBPChange = (event: React.ChangeEvent<{ value: unknown }>, index: number, account_id: number) => {
    const { value } = event.target;
    setSelectedBPMap((prevSelected) => ({
      ...prevSelected,
      [index]: value as string
    }));

    updateEditedAccounts(index, { analytics_bp: value as string });
  };
  const handleDREChange = (event: React.ChangeEvent<{ value: unknown }>, index: number) => {
    const { value } = event.target;
    setSelectedDREMap((prevSelected) => ({
      ...prevSelected,
      [index]: value as string
    }));

    updateEditedAccounts(index, { analytics_dre: value as string });
  };

  const handleNcgChange = (event: React.ChangeEvent<{ value: unknown }>, index: number) => {
    const { value } = event.target;
    setSelectedNcgMap((prevSelected) => ({
      ...prevSelected,
      [index]: value as string
    }));

    updateEditedAccounts(index, { analytics_ncg: value as string });
  };

  const handleFcChange = (event: React.ChangeEvent<{ value: unknown }>, index: number) => {
    const { value } = event.target;
    setSelectedFcMap((prevSelected) => ({
      ...prevSelected,
      [index]: value as string
    }));

    updateEditedAccounts(index, { analytics_fc: value as string });
  };

  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from} - ${to} de ${count !== -1 ? count : `mais de ${to}`}`;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };
  const handleSaveAccounts = async () => {
    dispatch(showSpinner());
    try {
      const selectedData = [];

      Object.entries(editedAccounts).forEach(([index, editedRow]) => {
        const account = analyticsAccounts[index];
        const { analytics_bp, analytics_dre, analytics_ncg, analytics_fc } = editedRow;

        const rowData = {
          chart_accounts: item.id,
          id: account.id,
          code: account.code,
          description: account.description,
          parent: account.parent,
          analytics_bp,
          analytics_dre,
          analytics_ncg,
          analytics_fc
        };

        selectedData.push(rowData);
      });

      await AnalyticsAccountService.updateMany(selectedData);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Contas Salvas com sucesso!',
          severity: 'success'
        })
      );
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao Salvar Contas',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleReplicateAccount = (item: AnalyticsAccounts, valueSelected: string, analytic: string) => {
    const itemFields = { ...item, valueSelected: valueSelected, analytic: analytic };
    setReplicateAccount(itemFields);
  };
  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };
  const handleDeleteItem = (item: AnalyticsAccounts) => {
    setRemoveAccount(item);
  };

  const handleDeleteItemCancel = () => {
    setRemoveAccount(undefined);
  };

  const handleDeleteItemConfirm = async () => {
    dispatch(showSpinner());

    if (!removeAccount.parent) {
      try {
        const response = await AnalyticsAccountService.get({ parent: removeAccount.id });
        if (response?.data?.results) {
          const dataParents = response?.data?.results;
          const dataParentsIds = dataParents.map((item: any) => item.id);
          const list_ids = [...dataParentsIds, removeAccount.id];

          await AnalyticsAccountService.deleteMany(list_ids);
          setSaved((prevSaved) => (!prevSaved ? 1 : 0));

          dispatch(
            showSnackbarAlert({
              title: 'Sucesso',
              message: 'Contas Removidas com sucesso!',
              severity: 'success'
            })
          );
        }
      } catch (error: any) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: 'Erro ao remover Contas',
            severity: 'error'
          })
        );
      }
    } else {
      try {
        await AnalyticsAccountService.delete(removeAccount?.id);
        setSaved((prevSaved) => (!prevSaved ? 1 : 0));
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso',
            message: 'Conta Removida com sucesso!',
            severity: 'success'
          })
        );
      } catch (error: any) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: 'Erro ao remover Conta',
            severity: 'error'
          })
        );
      }
    }
    setRemoveAccount(undefined);
    dispatch(hideSpinner());
  };

  const handleReplicateChildrens = async (account: AnalyticsAccounts, all_childrens: boolean) => {
    dispatch(showSpinner());
    const indexMap: Record<string, Record<number, string>> = {
      bp: bpIndexes,
      dre: dreIndexes,
      ncg: ncgIndexes,
      fc: fcIndexes
    };
    const selectedMap: Record<string, Record<number, string>> = {
      bp: selectedBPMap,
      dre: selectedDREMap,
      ncg: selectedNcgMap,
      fc: selectedFcMap
    };
    const selected = selectedMap[account.analytic];
    const filteredAccounts = analyticsAccounts.filter((item) => {
      if (all_childrens) {
        return item.parent === account?.id;
      } else {
        const index = indexMap[account.analytic][item?.id];
        return item.parent === account?.id && selected[index] === '';
      }
    });
    filteredAccounts.forEach((item) => {
      switch (account.analytic) {
        case 'bp':
          selectedBPMap[bpIndexes[item?.id]] = account?.valueSelected;
          updateEditedAccounts(bpIndexes[item?.id], { analytics_bp: account?.valueSelected as string });
          break;
        case 'dre':
          selectedDREMap[dreIndexes[item?.id]] = account?.valueSelected;
          updateEditedAccounts(dreIndexes[item?.id], { analytics_dre: account?.valueSelected as string });
          break;
        case 'ncg':
          selectedNcgMap[ncgIndexes[item?.id]] = account?.valueSelected;
          updateEditedAccounts(ncgIndexes[item?.id], { analytics_ncg: account?.valueSelected as string });
          break;
        case 'fc':
          selectedFcMap[fcIndexes[item?.id]] = account?.valueSelected;
          updateEditedAccounts(fcIndexes[item?.id], { analytics_fc: account?.valueSelected as string });
          break;
        default:
          dispatch(
            showSnackbarAlert({
              title: 'Erro',
              message: 'Erro ao replicar valores',
              severity: 'error'
            })
          );
      }
    });
    dispatch(hideSpinner());
    setReplicateAccount(undefined);
  };

  const fetchAccounts = async () => {
    try {
      const response = await AnalyticsAccountService.get({ chart_accounts: item.id }, page, rowsPerPage);
      if (response?.data?.results) {
        const dataAccounts = response?.data?.results;
        setAnalyticsAccounts(dataAccounts);
        setCountPagination(response?.data?.count);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Contas',
          severity: 'error'
        })
      );
    }
  };

  const fetchAnalyticsBP = async () => {
    try {
      const response = await AnalyticsService.get({ analytics_type_id: item.analytics_type_bp.id, list_children: 1 });
      if (response?.data?.results) {
        const dataAnalytics = response?.data?.results;
        setAnalyticsBP(dataAnalytics);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Contas',
          severity: 'error'
        })
      );
    }
  };

  const fetchAnalyticsDRE = async () => {
    try {
      const response = await AnalyticsService.get({ analytics_type_id: item.analytics_type_dre.id, list_children: 1 });
      if (response?.data?.results) {
        const dataAnalytics = response?.data?.results;
        setAnalyticsDRE(dataAnalytics);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Contas',
          severity: 'error'
        })
      );
    }
  };
  const fetchAnalyticsNcg = async () => {
    try {
      const response = await AnalyticsService.get({ analytics_type_id: item.analytics_type_ncg.id, list_children: 1 });
      if (response?.data?.results) {
        const dataAnalytics = response?.data?.results;
        setAnalyticsNcg(dataAnalytics);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Contas',
          severity: 'error'
        })
      );
    }
  };
  const fetchAnalyticsFc = async () => {
    try {
      const response = await AnalyticsService.get({ analytics_type_id: item.analytics_type_fc.id, list_children: 1 });
      if (response?.data?.results) {
        const dataAnalytics = response?.data?.results;
        setAnalyticsFc(dataAnalytics);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Contas',
          severity: 'error'
        })
      );
    }
  };

  const fetchAccountsParents = async () => {
    try {
      const response = await AnalyticsAccountService.get({ is_analytical: false, chart_accounts: item.id });
      if (response?.data?.results) {
        const dataAccounts = response?.data?.results;
        setAnalyticsAccountsParents(
          dataAccounts.map((account: AnalyticsAccounts) => ({
            id: account.id,
            name: account.code + ' - ' + account.description
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Contas',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    const bpMap = {};
    const dreMap = {};
    const ncgMap = {};
    const fcMap = {};
    const bpIndex = {};
    const dreIndex = {};
    const ncgIndex = {};
    const fcIndex = {};

    analyticsAccounts.forEach((account, index) => {
      bpMap[index] = account.analytics_bp ? account.analytics_bp.id : '';
      dreMap[index] = account.analytics_dre ? account.analytics_dre.id : '';
      ncgMap[index] = account.analytics_ncg ? account.analytics_ncg.id : '';
      fcMap[index] = account.analytics_fc ? account.analytics_fc.id : '';
      bpIndex[account?.id] = index;
      dreIndex[account?.id] = index;
      ncgIndex[account?.id] = index;
      fcIndex[account?.id] = index;
    });

    setSelectedBPMap(bpMap);
    setSelectedDREMap(dreMap);
    setSelectedNcgMap(ncgMap);
    setSelectedFcMap(fcMap);
    setBPIndexes(bpIndex);
    setDREIndexes(dreIndex);
    setNcgIndexes(ncgIndex);
    setFcIndexes(fcIndex);
  }, [analyticsAccounts]);

  useEffect(() => {
    (async () => {
      dispatch(showSpinner());
      setLoading(true);

      await fetchAccounts();

      setLoading(false);
      dispatch(hideSpinner());
    })();
  }, [saved, page, rowsPerPage]);

  useEffect(() => {
    (async () => {
      dispatch(showSpinner());
      setLoading(true);

      await Promise.all([fetchAccounts(), fetchAnalyticsBP(), fetchAnalyticsDRE(), fetchAnalyticsNcg(), fetchAnalyticsFc()]);

      dispatch(hideSpinner());
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {!loading && (
        <Box sx={{ display: 'flex', flexDirection: 'column', px: 4, pt: 1 }}>
          <Box>
            <Typography variant="h4">Configurar Plano de Contas</Typography>

            <Stack flexDirection="row" columnGap={2} justifyContent="flex-end">
              <Tooltip title="Criar Conta">
                <Button startIcon={<AddIcon />} variant="outlined" onClick={handleNewAccount}>
                  Adicionar Conta
                </Button>
              </Tooltip>
              <Tooltip title="Criar Conta">
                <Button startIcon={<CheckIcon />} variant="contained" onClick={handleSaveAccounts}>
                  Salvar
                </Button>
              </Tooltip>
            </Stack>
            <TableContainer component={Paper} sx={{ mt: 1, borderRadius: '4px', p: 1, maxHeight: 'calc(100vh - 230px)' }} elevation={1}>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        Código da Conta
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        Descrição da Conta
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        Código da Conta (BP)
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        Código da Conta (DRE)
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        Código da Conta (NCG)
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        Código da Conta (FC)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {analyticsAccounts && analyticsAccounts?.length > 0 ? (
                    <>
                      {analyticsAccounts.map((account, index) => (
                        <StyledTableRow key={index}>
                          <TableCell style={{ paddingLeft: account.parent ? '20px' : '5px' }}>
                            <Typography variant="h6">{account.parent ? account.code : <strong>{account.code}</strong>}</Typography>
                          </TableCell>
                          <TableCell style={{ paddingLeft: account.parent ? '20px' : '5px' }}>
                            <Typography variant="h6">
                              {account.parent ? account.description : <strong>{account.description}</strong>}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <FormControl sx={{ m: 1, width: 240 }} size="small">
                                <InputLabel id="demo-select-small-label">BP</InputLabel>
                                <Select
                                  labelId={`demo-select-small-label-${index}`}
                                  id={`demo-select-small-${index}`}
                                  name="bp"
                                  value={selectedBPMap[index] || ''}
                                  onChange={(event) => handleBPChange(event, index, account?.id)}
                                  style={{ fontSize: '9px' }}
                                >
                                  <MenuItem value={''}>Nenhum</MenuItem>
                                  {analyticsBP.map((bp) => (
                                    <MenuItem key={bp.id} value={bp.id}>
                                      {bp.code} - {bp.description}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {!account.parent && (
                                <Tooltip title="Replicar">
                                  <IconButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleReplicateAccount(account, selectedBPMap[index] || '', 'bp');
                                    }}
                                  >
                                    <KeyboardDoubleArrowDownIcon fontSize="medium" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <FormControl sx={{ m: 1, width: 240 }} size="small">
                                <InputLabel id="demo-select-small-label">DRE</InputLabel>
                                <Select
                                  labelId={`demo-select-small-label-${index}`}
                                  id={`demo-select-small-${index}`}
                                  value={selectedDREMap[index] || ''}
                                  onChange={(event) => handleDREChange(event, index)}
                                  style={{ fontSize: '9px' }}
                                >
                                  <MenuItem value={''}>Nenhum</MenuItem>
                                  {analyticsDRE.map((bp) => (
                                    <MenuItem key={bp.id} value={bp.id}>
                                      {bp.code} - {bp.description}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {!account.parent && (
                                <Tooltip title="Replicar">
                                  <IconButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleReplicateAccount(account, selectedDREMap[index] || '', 'dre');
                                    }}
                                  >
                                    <KeyboardDoubleArrowDownIcon fontSize="medium" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <FormControl sx={{ m: 1, width: 240 }} size="small">
                                <InputLabel id="demo-select-small-label">NCG</InputLabel>
                                <Select
                                  labelId={`demo-select-small-label-${index}`}
                                  id={`demo-select-small-${index}`}
                                  value={selectedNcgMap[index] || ''}
                                  onChange={(event) => handleNcgChange(event, index)}
                                  style={{ fontSize: '9px' }}
                                >
                                  <MenuItem value={''}>Nenhum</MenuItem>
                                  {analyticsNcg.map((bp) => (
                                    <MenuItem key={bp.id} value={bp.id}>
                                      {bp.code} - {bp.description}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {!account.parent && (
                                <Tooltip title="Replicar">
                                  <IconButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleReplicateAccount(account, selectedNcgMap[index] || '', 'ncg');
                                    }}
                                  >
                                    <KeyboardDoubleArrowDownIcon fontSize="medium" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <FormControl sx={{ m: 1, width: 240 }} size="small">
                                <InputLabel id="demo-select-small-label">FC</InputLabel>
                                <Select
                                  labelId={`demo-select-small-label-${index}`}
                                  id={`demo-select-small-${index}`}
                                  value={selectedFcMap[index] || ''}
                                  onChange={(event) => handleFcChange(event, index)}
                                  style={{ fontSize: '9px' }}
                                >
                                  <MenuItem value={''}>Nenhum</MenuItem>
                                  {analyticsFc.map((bp) => (
                                    <MenuItem key={bp.id} value={bp.id}>
                                      {bp.code} - {bp.description}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {!account.parent && (
                                <Tooltip title="Replicar">
                                  <IconButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleReplicateAccount(account, selectedFcMap[index] || '', 'fc');
                                    }}
                                  >
                                    <KeyboardDoubleArrowDownIcon fontSize="medium" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Excluir">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteItem(account);
                                }}
                              >
                                <DeleteIcon fontSize="medium" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  ) : (
                    <StyledTableRow>
                      <TableCell align="center" colSpan={6}>
                        Nenhum registro encontrado.
                      </TableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {removeAccount && (
              <DialogComponent
                title="Remover Conta"
                description="Deseja remover esta Conta?"
                onCancel={handleDeleteItemCancel}
                onConfirm={handleDeleteItemConfirm}
              />
            )}
            {replicateAccount && (
              <ReplicateDialogComponent
                title="Replicar Valores"
                description="Replicar valor para todos Filhos ou somente os não preenchidos?"
                cancelText="Todos os Filhos"
                confirmText="Não preenchidos"
                allChildrens={() => handleReplicateChildrens(replicateAccount, true)}
                emptyChildrens={() => handleReplicateChildrens(replicateAccount, false)}
                account={replicateAccount}
              />
            )}
            {analyticsAccounts && (
              <TablePagination
                rowsPerPageOptions={[50, 100, 500, 1000]}
                component="div"
                count={countPagination || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Linhas por página"
                labelDisplayedRows={labelDisplayedRows}
              />
            )}
            <Divider />
          </Box>
        </Box>
      )}
      {newAccount && (
        <AnalyticsAccountsFormDialog
          onClose={handleCloseDialogs}
          parents={analyticsAccountsParents}
          chartAccounts={item}
          updateSaved={updateSaved}
        />
      )}
    </>
  );
};

export default ChartAccountConfigurationTab;
