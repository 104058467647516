import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Processing } from '../../../models/Processing';
import { User, getUserName } from '../../../models/User';
import { capitalize } from 'lodash';
import { formatDateTime } from '../../../utils/utils';
import DownloadIcon from '@mui/icons-material/Download';
import { useAuthContext } from '../../../context/AuthContextProvider';
import axios from 'axios';
import { priorityOptions } from '../ProcessingFormDialog';
import { Company } from '../../../models/Company';
import { Attachment } from '../../../models/Attachment';

interface Props {
  item: Processing;
  onClose: () => void;
}

export const ProcessingDetailsDialog = ({ item, onClose }: Props) => {

  const { accessToken } = useAuthContext();

  const priority = item.priority < 40 ? priorityOptions[0] : item.priority < 60 ? priorityOptions[1] : priorityOptions[2]

  const onDownloadFile = async (file: any) => {
    if (!accessToken) {
      console.error('Token de acesso não disponível.');
      return;
    }

    try {
      const response = await axios.get(file.file, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Erro ao fazer o download do arquivo:', error);
      // Lidar com o erro, exibir uma mensagem para o usuário, etc.
    }
  };

  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes do processo
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Arquivo:
            </Typography>
            <Typography variant='body1' gutterBottom>
              {item.ecd_file ?
                <Button
                  component="label"
                  variant="text"
                  color='secondary'
                  startIcon={<DownloadIcon />}
                  onClick={() => onDownloadFile(item.ecd_file)}
                >
                  {(item.ecd_file as Attachment).name}
                </Button>
                : 'Arquivo não encontrado'
              }
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Data de cadastro:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatDateTime(item.created_at)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Marcado para carga imediata:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.run_immediately ? 'Sim' : 'Não'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Responsável:
            </Typography>
            <Typography variant='body1' gutterBottom>
              {getUserName((item?.owner as User))}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Prioridade:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {priority.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Status:
            </Typography>
            <Typography variant='body1' gutterBottom>
              {capitalize(item.status_label)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Processamento iniciado em:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item?.start_at ? formatDateTime(item.start_at) : 'Não iniciado'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Processamento concluído em:
            </Typography>
            <Typography variant='body1' gutterBottom>
              {item?.finished_at ? formatDateTime(item.finished_at) : 'Não concluído'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Empresa:
            </Typography>
            <Typography variant='body1' gutterBottom>
              {(item.company as Company)?.company_name || ' - '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Último bloco processado:
            </Typography>
            <Typography variant='body1' gutterBottom>
              {item.last_processed_block ? `${item.last_processed_block.block} - ${item.last_processed_block.description}` : ' - '}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
