import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { IAnalyticsFCFilter } from '../models/AnalyticsFC';

const AnalysisFCUrl = '/reports/fc';

export const AnalysisFCService = {
  get: async (filters: IAnalyticsFCFilter[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(AnalysisFCUrl, {
        params: filters
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },
  post: async (data: IAnalyticsFCFilter[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(AnalysisFCUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
