import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { StyledTableRow } from '../../../../../components/basics/StyledTableRow';
import { SoldItemAnalyticItem, SoldItemAnalyticValue } from '../../../../../models/SoldItem';
import { MonthNameEnum } from '../../../../../models/SoldItemFile';
import { formatReportValue } from '../../../../../utils/utils';
import './DynamicTable.css';
import { useSoldItemAnalyticContext } from './context/SoldItemAnalyticContext';

export default function DynamicTable() {
  const { soldItemAnalytic } = useSoldItemAnalyticContext();

  return (
    <TableContainer style={{ overflow: 'auto', maxHeight: 'calc(100vh - 170px)' }}>
      {soldItemAnalytic && soldItemAnalytic?.items?.length > 0 ? (
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead sx={{ position: 'sticky', top: 0, zIndex: 3 }}>
            <TableRow>
              <TableCell key="empty-cell" colSpan={1} sx={{ position: 'sticky', left: 0, zIndex: 4 }} />
              {soldItemAnalytic?.record_month?.map((row: number, index: number) => {
                return (
                  <TableCell
                    colSpan={6}
                    style={{
                      borderRight: '0.5px solid #c7c7c7'
                    }}
                  >
                    <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography variant="subtitle1" textAlign={'center'}>
                        {MonthNameEnum[row]}
                      </Typography>
                    </Stack>
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell key="description-empty-cell" colSpan={1} sx={{ position: 'sticky', left: 0, zIndex: 4 }} />
              {soldItemAnalytic?.record_month?.map((row: number, index: number) => {
                return (
                  <>
                    <TableCell align="center" colSpan={3}>
                      <Typography variant="body1">Faturamento</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={3}
                      style={{
                        borderRight: '0.5px solid #c7c7c7'
                      }}
                    >
                      <Typography variant="body1">Custo</Typography>
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  zIndex: 4,
                  padding: 0,
                  position: 'sticky',
                  left: 0
                }}
              >
                <TableCell
                  align="center"
                  sx={{
                    minWidth: '100px'
                  }}
                >
                  <Typography variant="subtitle2">Código</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderRight: '0.5px solid #c7c7c7',
                    minWidth: '290px'
                  }}
                >
                  <Typography variant="subtitle2">Descrição</Typography>
                </TableCell>
              </TableCell>
              {soldItemAnalytic?.record_month?.map((row: number, index: number) => {
                return (
                  <>
                    <TableCell align="center" sx={{ minWidth: '120px' }}>
                      <Typography variant="subtitle2">Quantidade</Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '120px' }}>
                      <Typography variant="subtitle2">Valor Unitário</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        borderRight: '0.5px solid #c7c7c7',
                        minWidth: '130px'
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ minWidth: '120px' }}>
                        Valor Total
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '120px' }}>
                      <Typography variant="subtitle2">Valor Unitário</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2">Valor Total</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        borderRight: '0.5px solid #c7c7c7',
                        minWidth: '120px'
                      }}
                    >
                      <Typography variant="subtitle2">Margem de Custo</Typography>
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {soldItemAnalytic?.items?.map((row: SoldItemAnalyticItem, index: number) => {
              return (
                <>
                  <StyledTableRow>
                    <div style={{ display: 'flex', backgroundColor: 'white', padding: 0, position: 'sticky', left: 0, width: '390px' }}>
                      <TableCell
                        className="labelTableCell"
                        align="center"
                        sx={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 2,
                          minWidth: '100px'
                        }}
                      >
                        {row.code}
                      </TableCell>

                      <TableCell
                        className="labelTableCell"
                        align="center"
                        sx={{
                          position: 'sticky',
                          left: '100px',
                          zIndex: 2,
                          minWidth: '290px'
                        }}
                      >
                        {row.description}
                      </TableCell>
                    </div>
                    {row?.values?.map((row: SoldItemAnalyticValue, index: number) => {
                      return (
                        <>
                          <TableCell align="center">{row.quantity}</TableCell>
                          <TableCell align="center">{formatReportValue(row.unit_sales_price)}</TableCell>
                          <TableCell align="center">{formatReportValue(row.total_sales_price)}</TableCell>
                          <TableCell align="center">{formatReportValue(row.unit_cost_price)}</TableCell>
                          <TableCell align="center">{formatReportValue(row.total_cost_price)}</TableCell>
                          <TableCell
                            align="center"
                            style={{
                              borderRight: '0.5px solid #c7c7c7'
                            }}
                          >
                            {row.cost_margin.toString().replace('.', ',')}%
                          </TableCell>
                        </>
                      );
                    })}
                  </StyledTableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Box sx={{ display: 'flex', width: '100%', p: 4, justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h6">Não há dados dessa empresa.</Typography>
        </Box>
      )}
    </TableContainer>
  );
}
