// BarChart.js
import ReactECharts from 'echarts-for-react';
import { useAnalyticsFCTableContext } from '../../context/TableContext';

export const GraphComponentFc = () => {
  const { columns } = useAnalyticsFCTableContext();

  const EXIBE_PONTOS_DA_LINHA = true;

  const generateValues = () => {
    return Array.from({ length: 10 }, (_, index) => Math.floor(Math.random() * 100));
  };

  const option = {
    xAxis: {
      type: 'category',
      data: columns.map((column) => column.name) // Seus dados aqui
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        type: 'bar',
        data: generateValues()
      },
      {
        type: 'bar',
        data: generateValues()
      },
      {
        type: 'line',
        data: generateValues(),
        label: {
          show: EXIBE_PONTOS_DA_LINHA,
          position: 'top'
        }
      }
    ]
  };

  return <ReactECharts option={option} style={{ height: '300px' }} />;
};
