import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  selectionQuantity: number;
  year: [];
  setYear: (year: string[]) => void;
}

export const YearSelectorComponent = ({ selectionQuantity, year, setYear }: Props) => {
  const YEARS_DISPLAY_QUANTITY = 6;
  const YEARS_CHANGE_QUANTITY = 2;

  const MIN_YEARS = 10;

  const [yearList, setYearList] = useState<string[]>([]);

  const createYearListToBeSelected = () => {
    const currentYear = new Date().getFullYear();
    const yearList = Array.from({ length: YEARS_DISPLAY_QUANTITY }, (_, i) => (currentYear - i).toString());
    setYearList(yearList);
  };

  const handleYearChange = (selectedYear: string) => {
    if (selectionQuantity == 1) {
      setYear([selectedYear]);
      return;
    }

    if (year.includes(selectedYear)) {
      setYear(year.filter((item) => item !== selectedYear));
      return;
    }

    if (year.length < selectionQuantity) {
      setYear([...year, selectedYear]);
    } else {
      setYear(year.slice(1).concat(selectedYear));
    }
  };

  const handleYearsChangeUpward = () => {
    setYearList((prevYearList) => {
      const firstYear = parseInt(prevYearList[0]);
      //não permite avançar o ano atual
      if (firstYear === new Date().getFullYear()) return prevYearList;

      const newYears = Array.from({ length: YEARS_CHANGE_QUANTITY }, (_, i) => (firstYear + i + 1).toString()).reverse();
      let updatedYearList = newYears.concat(prevYearList).slice(0, YEARS_DISPLAY_QUANTITY);
      return updatedYearList;
    });
  };

  const handleListChangeDownward = () => {
    setYearList((prevYearList) => {
      const firstYear = parseInt(prevYearList[prevYearList.length - 1]);
      //não permite retroceder mais que 10 anos
      if (firstYear <= new Date().getFullYear() - MIN_YEARS) return prevYearList;

      const newYears = Array.from({ length: YEARS_CHANGE_QUANTITY }, (_, i) => (firstYear - i - 1).toString());
      let updatedYearList = prevYearList.slice(YEARS_CHANGE_QUANTITY, YEARS_DISPLAY_QUANTITY).concat(newYears);
      return updatedYearList;
    });
  };

  useEffect(() => {
    createYearListToBeSelected();
  }, []);

  return (
    <Stack>
      <Box>
        <Stack rowGap={1}>
          <IconButton onClick={handleYearsChangeUpward}>
            <KeyboardArrowUpIcon />
          </IconButton>
          {yearList.map((item) => (
            <Button key={item} variant={year.includes(item) ? 'contained' : 'outlined'} onClick={() => handleYearChange(item)}>
              {item}
            </Button>
          ))}
          <IconButton onClick={handleListChangeDownward}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </Stack>
      </Box>
    </Stack>
  );
};
