import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { IAnalyticsDREBudget, IAnalyticsDRERowBudget } from '../../../../models/AnalyticsDRE';
import { ApiResults } from '../../../../models/ApiResults';
import { Budget } from '../../../../models/Budget';
import TableRowRecursive from './TableRowRecursive';

export interface BudgetResult extends ApiResults {
  results: Budget[];
}
interface BudgetItemTableProps {
  item: Budget;
  reportDreData: IAnalyticsDREBudget;
}

export const BudgetItemTable = ({ item, reportDreData }: BudgetItemTableProps) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1, paddingBottom: '60px' }}>
        <Box>
          <TableContainer component={Paper} sx={{ borderRadius: '4px' }} elevation={1}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: '0.5px solid #c7c7c7'
                    }}
                  >
                    <Typography variant="h5" fontSize="12px">
                      Conta Contábil
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '0.5px solid #c7c7c7'
                    }}
                  >
                    <Typography variant="h5" fontSize="10px" sx={{ textAlign: 'center' }}>
                      Média Ano Anterior
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '0.5px solid #c7c7c7'
                    }}
                  >
                    <Typography variant="h5" fontSize="10px" minWidth={100} sx={{ textAlign: 'center' }}>
                      Média dos últimos 6 meses do ano anterior
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '0.5px solid #c7c7c7'
                    }}
                  >
                    <Typography variant="h5" fontSize="10px" minWidth={100} sx={{ textAlign: 'center' }}>
                      Média dos últimos 3 meses do ano anterior
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '0.5px solid #c7c7c7'
                    }}
                  >
                    <Typography variant="h5" fontSize="10px" minWidth={100} sx={{ textAlign: 'center' }}>
                      Valor Mês Referência do ano anterior
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px">
                      Janeiro
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px">
                      Fevereiro
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px">
                      Março
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px">
                      Abril
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px">
                      Maio
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px">
                      Junho
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px">
                      Julho
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px">
                      Agosto
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px">
                      Setembro
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px">
                      Outubro
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px">
                      Novembro
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '0.5px solid #c7c7c7'
                    }}
                  >
                    <Typography variant="h5" fontSize="12px">
                      Dezembro
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px">
                      Acumulado Ano
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportDreData && (
                  <>
                    {reportDreData?.report?.map((row: IAnalyticsDRERowBudget, index: number) => (
                      <TableRowRecursive key={row.id} row={row} budget={item} />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
        </Box>
      </Box>
    </>
  );
};

export default BudgetItemTable;
