import { DialogWithToggleFullscreen } from '../../../../../../components/basics/DialogWithToggleFullscreen';
import { IAnalysisDreModal } from '../context/TableContext';
import { GraphComponentDre } from './GraphComponentDre';

interface Props {
  modalData: IAnalysisDreModal;
  onClose: () => void;
  onMinimize: () => void;
  onToggleModalFullscreen: () => void;
}

export const ChartModal = ({ modalData, onClose, onMinimize, onToggleModalFullscreen }: Props) => {
  return (
    <DialogWithToggleFullscreen
      open={!!modalData}
      onClose={onClose}
      title="Gráfico"
      onMinimize={onMinimize}
      fullscreen={modalData.fullscreen}
      toggleFullscreen={onToggleModalFullscreen}
    >
      <GraphComponentDre />
    </DialogWithToggleFullscreen>
  );
};
