import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { IAnalyticsBPFilter } from '../models/AnalyticsBP';

const AnalysisBPUrl = '/reports/bp';

export const AnalysisBPService = {
  get: async (filters: IAnalyticsBPFilter[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(AnalysisBPUrl, {
        params: filters
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  post: async (data: IAnalyticsBPFilter[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(AnalysisBPUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  getChartData: async (filters: IAnalyticsBPFilter[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(`${AnalysisBPUrl}/chart`, {
        params: filters
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
