import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { AnalyticsType } from '../models/AnalyticsType';
import { AnalyticsTypeFilterFields } from '../sections/@analytics-type/context/AnalyticsTypeContext';

const AnalyticsTypeUrl = '/analytics/types/';

export const AnalyticsTypeService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(AnalyticsTypeUrl, {params: {page_size: 0}});
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: AnalyticsTypeFilterFields, page: number = 0, page_size: number = 5) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(AnalyticsTypeUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  getOne: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(`${AnalyticsTypeUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: AnalyticsType) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(AnalyticsTypeUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: AnalyticsType) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${AnalyticsTypeUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${AnalyticsTypeUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${AnalyticsTypeUrl}batch-delete/`, {
        ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
