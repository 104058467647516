import FilterListIcon from '@mui/icons-material/FilterList';
import ColumnsIcon from '@mui/icons-material/ViewColumn';
import { Button, Stack, useTheme } from '@mui/material';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useAnalyticsBPTableContext } from '../context/TableContext';
import { ColumnsPopover } from './ColumnsPopover';
import { FilterPopoverRange } from './FilterPopoverRange';
import { GraphsPopover } from './GraphsPopover';
import { LevelsPopover } from './LevelsPopover';

interface DateRange {
  startDate: string;
  endDate: string;
  retroStartDate: string;
  retroEndDate: string;
}

export const TableHeaderToolbar = () => {
  const theme = useTheme();
  const filterButtonRef = useRef<HTMLButtonElement | null>(null);

  const { columns } = useAnalyticsBPTableContext();

  const [graphsAnchorEl, setGraphsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [columnsAnchorEl, setColumnsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [levelsAnchorEl, setLevelsAnchorEl] = useState<HTMLButtonElement | null>(null);

  //alternativa para manter o filtro na DOM com os seus valores, mas não visível
  const [filterVisible, setFilterVisible] = useState(false);

  const handleDisplayGraphsPopover = (event?: MouseEvent<HTMLButtonElement> | null) => {
    setGraphsAnchorEl(event?.currentTarget || null);
  };

  const handleDisplayFilterPopover = (event?: MouseEvent<HTMLButtonElement> | null) => {
    setFilterAnchorEl(event?.currentTarget || filterButtonRef.current);
    setFilterVisible(!filterVisible);
  };

  const handleDisplayColumnsPopover = (event?: MouseEvent<HTMLButtonElement> | null) => {
    setColumnsAnchorEl(event?.currentTarget || null);
  };

  const handleDisplayLevelsPopover = (event?: MouseEvent<HTMLButtonElement> | null) => {
    setLevelsAnchorEl(event?.currentTarget || null);
  };

  useEffect(() => {
    if (columns?.length == 0 && filterButtonRef.current) {
      //mostrar o filtro passando um anchorEl do botão de filtro
      handleDisplayFilterPopover();
    }
  }, [columns, filterButtonRef]);

  return (
    <>
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', p: 2 }} gap={1}>
        {/* <Button variant="contained" color="primary" onClick={handleDisplayGraphsPopover} startIcon={<BarChartIcon />}>
          Gráficos
        </Button> */}
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => handleDisplayFilterPopover(event)}
          startIcon={<FilterListIcon />}
          ref={filterButtonRef}
        >
          Filtros
        </Button>
        <Button variant="contained" color="primary" onClick={handleDisplayColumnsPopover} startIcon={<ColumnsIcon />}>
          Colunas
        </Button>
        <Button variant="contained" color="primary" onClick={(event) => handleDisplayLevelsPopover(event)} startIcon={<FilterListIcon />}>
          Níveis
        </Button>
      </Stack>
      <FilterPopoverRange anchorEl={filterAnchorEl} visible={!!filterVisible} onClose={() => setFilterVisible(false)} />
      <ColumnsPopover anchorEl={columnsAnchorEl} onClose={() => setColumnsAnchorEl(null)} />
      <LevelsPopover anchorEl={levelsAnchorEl} onClose={() => setLevelsAnchorEl(null)} />
      <GraphsPopover anchorEl={graphsAnchorEl} onClose={() => setGraphsAnchorEl(null)} />
    </>
  );
};
