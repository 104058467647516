import { Button, DialogContent, Popover, Stack } from '@mui/material';
import { useAnalyticsBPTableContext } from '../../context/TableContext';

interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export const LevelsPopover = ({ anchorEl, onClose }: Props) => {
  const { openLevels, toggleLevel } = useAnalyticsBPTableContext(); // Use o hook useLevel

  const handleLevelClick = (level: number) => {
    //fechar todos os niveis acima do nivel clicado
    Object.keys(openLevels).forEach((key) => {
      if (parseInt(key) > level) {
        if (openLevels[parseInt(key)] === true) {
          toggleLevel(parseInt(key));
        }
      }
    });

    //abrir todos os niveis abaixo do nivel clicado
    Object.keys(openLevels).forEach((key) => {
      if (parseInt(key) < level) {
        if (openLevels[parseInt(key)] === false) {
          toggleLevel(parseInt(key));
        }
      }
    });

    toggleLevel(level);
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      sx={{ mt: 2 }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <DialogContent>
        <Stack spacing={1} direction="row">
          {[1, 2, 3, 4].map((level) => (
            <Button key={level} onClick={() => handleLevelClick(level)} variant={openLevels[level] ? 'contained' : 'outlined'}>
              {`Nível ${level}`}
            </Button>
          ))}
        </Stack>
      </DialogContent>
    </Popover>
  );
};
