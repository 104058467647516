import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ManualInput } from '../../../models/ManualInput';
import { formatCurrency, formatSimpleDate } from '../../../utils/utils';

interface Props {
  item: ManualInput;
  onClose: () => void;
}

export const ManualInputDetailsDialog = ({ item, onClose }: Props) => {
  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes do Lançamento
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Empresa:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.company.company_name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Data:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatSimpleDate(item.entry_date)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Histórico:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.history}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Centro de Custo Débito:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.debit_cost_center || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Centro de Custo Crédito:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.credit_cost_center || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Conta Débito:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.debit_account.code} - {item.debit_account.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Conta Crédito:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.credit_account.code} - {item.credit_account.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Valor:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatCurrency(item.value)}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
