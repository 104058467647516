import { Box, Paper, Typography } from '@mui/material';
import DynamicTableNCG from './DynamicTableNCG/TableComponent';
import { TableHeaderToolbar } from './DynamicTableNCG/TableHeaderToolbar';
import { useAnalyticsNCGTableContext } from './DynamicTableNCG/context/TableContext';

export interface ITableColumn {
  id: string;
  name: string;
  visible: boolean;
  index?: number;
  disableHide?: boolean;
  year: string;
}

interface Props {
  visible: boolean;
}

export const AnalyticNcgTab = () => {
  const { data } = useAnalyticsNCGTableContext();

  return (
    <Box sx={{ width: '100%' }}>
      <Paper>
        <TableHeaderToolbar />
        {!data.report ? (
          <Box sx={{ display: 'flex', width: '100%', p: 4, justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h5" color="secondary">
              Selecione o filtro e aplique para buscar os dados da análise
            </Typography>
          </Box>
        ) : data.report.length == 0 ? (
          <Box sx={{ display: 'flex', width: '100%', p: 4, justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h5">Nenhum dado encontrado para os filtros selecionados</Typography>
          </Box>
        ) : (
          <DynamicTableNCG />
        )}
      </Paper>
    </Box>
  );
};
