import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

export const ProcessingRoutes: INavGroup = {
  id: 'group-processing',
  title: 'Entradas',
  icon: <AccountTreeIcon />,
  type: 'group',
  module: 'INPUTS',
  level: 1,
  children: [
    {
      id: 'processing',
      title: 'Importação SPED',
      type: 'item',
      url: ROUTES.authenticated.accountingEntries.spedImport,
      icon: <AccountTreeIcon />,
      breadcrumbs: true,
      submodule: 'INPUTS'
    },
    {
      id: 'manual-transactions',
      title: 'Lançamentos manuais',
      type: 'item',
      url: ROUTES.authenticated.accountingEntries.manualInputs,
      icon: <DrawOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'MANUAL_ACCOUNTING_ENTRY'
    },
    {
      id: 'sold-items',
      title: 'Itens Vendidos',
      type: 'item',
      url: ROUTES.authenticated.accountingEntries.soldItems,
      icon: <ReceiptOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'SOLD_ITEM_FILE'
    },
    {
      id: 'budgets',
      title: 'Orçamentos',
      type: 'item',
      url: ROUTES.authenticated.accountingEntries.budget,
      icon: <CreditCardOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'BUDGET'
    }
  ]
};
