import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, IconButton, InputLabel, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { AnalyticsAccounts } from '../../../models/AnalyticsAccounts';
import { CompanyGroup } from '../../../models/CompanyGroup';
import { AnalyticsAccountService } from '../../../services/AnalyticsAccount.service';
import { CompaniesGroupsService } from '../../../services/CompaniesGroups.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { CompanyGroupConfigurationScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useCompaniesGroupsContext } from '../context/CompaniesGroupContext';

const transformAnalyticsAccounts = (accounts) => {
  return accounts.map((account) => ({
    id: account.id,
    name: `${account.code} - ${account.description}`
  }));
};

interface Props {
  editItem?: CompanyGroup;
  onClose: () => void;
}

export const CompanyGroupConfiguration = ({ editItem, onClose }: Props) => {
  const [analyticsAccounts, setAnalyticsAccounts] = useState<SelectOption[]>([]);
  const dispatch = useDispatch();
  const { fetchCompaniesGroups } = useCompaniesGroupsContext();

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<CompanyGroup>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CompanyGroupConfigurationScheme),
    defaultValues: editItem
      ? {
          ...editItem,
          analytics_accounts_sale: editItem.analytics_accounts_sale ? transformAnalyticsAccounts(editItem.analytics_accounts_sale) : [],
          analytics_accounts_purchase: editItem.analytics_accounts_purchase
            ? transformAnalyticsAccounts(editItem.analytics_accounts_purchase)
            : []
        }
      : {}
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [salesFields, setSalesFields] = useState<string[]>([]);
  const [purchaseFields, setPurchaseFields] = useState<string[]>([]);
  const [returnSalesFields, setReturnSalesFields] = useState<string[]>([]);
  const [returnPurchaseFields, setReturnPurchaseFields] = useState<string[]>([]);

  const addCFOPSale = () => {
    setSalesFields([...salesFields, '']);
  };

  const addCFOPPurchase = () => {
    setPurchaseFields([...purchaseFields, '']);
  };

  const addCFOPReturnSale = () => {
    setReturnSalesFields([...returnSalesFields, '']);
  };

  const addCFOPReturnPurchase = () => {
    setReturnPurchaseFields([...returnPurchaseFields, '']);
  };

  const removeCFOPSale = (index: number) => {
    const newSalesFields = [...salesFields];
    newSalesFields.splice(index, 1);
    setSalesFields(newSalesFields);
  };
  const removeCFOPPurchase = (index: number) => {
    const newPurchaseFields = [...purchaseFields];
    newPurchaseFields.splice(index, 1);
    setPurchaseFields(newPurchaseFields);
  };

  const removeCFOPReturnSale = (index: number) => {
    const newReturnSaleFields = [...returnSalesFields];
    newReturnSaleFields.splice(index, 1);
    setReturnSalesFields(newReturnSaleFields);
  };

  const removeCFOPReturnPurchase = (index: number) => {
    const newReturnPurchaseFields = [...returnPurchaseFields];
    newReturnPurchaseFields.splice(index, 1);
    setReturnPurchaseFields(newReturnPurchaseFields);
  };

  const getCFOPs = async () => {
    let sales: string[] = [];
    let purchases: string[] = [];
    let returnSales: string[] = [];
    let returnPurchases: string[] = [];

    if (editItem && Array.isArray(editItem.cfops)) {
      editItem.cfops.forEach((cfop) => {
        switch (cfop.cfop_type) {
          case 'sale':
            sales.push(cfop.cfop);
            break;
          case 'purchase':
            purchases.push(cfop.cfop);
            break;
          case 'return_sale':
            returnSales.push(cfop.cfop);
            break;
          case 'return_purchase':
            returnPurchases.push(cfop.cfop);
            break;
        }
      });
    }

    setSalesFields(sales);
    setPurchaseFields(purchases);
    setReturnSalesFields(returnSales);
    setReturnPurchaseFields(returnPurchases);
  };

  const fetchAccounts = async () => {
    dispatch(showSpinner());
    try {
      const params = editItem ? { chart_accounts: editItem?.chart_accounts?.id, is_analytical: 1 } : { is_analytical: 1 };
      const response = await AnalyticsAccountService.get(params);
      if (response?.data?.results) {
        const dataAccounts = response?.data?.results;
        setAnalyticsAccounts(
          dataAccounts.map((account: AnalyticsAccounts) => ({
            id: account.id,
            name: `${account.code} - ${account.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Contas',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const transformDataForAPI = () => {
    let cfops = [];
    salesFields.forEach((field) => cfops.push({ cfop: field, cfop_type: 'sale' }));
    purchaseFields.forEach((field) => cfops.push({ cfop: field, cfop_type: 'purchase' }));
    returnSalesFields.forEach((field) => cfops.push({ cfop: field, cfop_type: 'return_sale' }));
    returnPurchaseFields.forEach((field) => cfops.push({ cfop: field, cfop_type: 'return_purchase' }));
    return { cfops };
  };
  const createOrUpdateCompanyGroup: SubmitHandler<CompanyGroup> = async (data: Partial<CompanyGroup>) => {
    dispatch(showSpinner());
    try {
      let payload = transformDataForAPI() as Partial<CompanyGroup>;
      payload = {
        ...payload,
        analytics_accounts_sale: data.analytics_accounts_sale.map((account) => account.id),
        analytics_accounts_purchase: data.analytics_accounts_purchase.map((account) => account.id)
      };

      await CompaniesGroupsService.partialUpdate(editItem?.id!, payload);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Grupo de Empresa atualizada com sucesso!' : 'Grupo de Empresa criada com sucesso!',
          severity: 'success'
        })
      );
      fetchCompaniesGroups();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchAccounts();
      if (editItem?.cfops) {
        dispatch(showSpinner());
        await getCFOPs();
        dispatch(hideSpinner());
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Dialog open={!loading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Configurar CFOP's e Contas
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateCompanyGroup)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="sale">CFOP de Venda</InputLabel>
              {salesFields.map((field, index) => (
                <Grid item key={field + index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                  <TextField
                    name={`sale.${index}.value`}
                    placeholder="CFOP de Venda"
                    defaultValue={salesFields[index]}
                    size="small"
                    sx={{ width: '100%' }}
                    onChange={(e) => {
                      const newSalesFields = [...salesFields];
                      newSalesFields[index] = e.target.value;
                      setSalesFields(newSalesFields);
                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => {
                      removeCFOPSale(index);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              ))}
              <IconButton color="primary" onClick={() => addCFOPSale()}>
                <AddIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="purchase">CFOP de Compra</InputLabel>
              {purchaseFields.map((field, index) => (
                <Grid item key={field + index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                  <TextField
                    name={`purchase.${index}.value`}
                    placeholder="CFOP de Compra"
                    size="small"
                    sx={{ width: '100%' }}
                    onChange={(e) => {
                      const newPurchaseFields = [...purchaseFields];
                      newPurchaseFields[index] = e.target.value;
                      setPurchaseFields(newPurchaseFields);
                    }}
                    defaultValue={purchaseFields[index]}
                  />
                  <IconButton
                    size="small"
                    onClick={() => {
                      removeCFOPPurchase(index);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              ))}
              <IconButton color="primary" onClick={() => addCFOPPurchase()}>
                <AddIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="return_sale">CFOP de Devolução Venda</InputLabel>
              {returnSalesFields.map((field, index) => (
                <Grid item key={field + index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                  <TextField
                    name={`return_sale.${index}.value`}
                    placeholder="CFOP de Devolução Venda"
                    size="small"
                    sx={{ width: '100%' }}
                    onChange={(e) => {
                      const newReturnSalesFields = [...returnSalesFields];
                      newReturnSalesFields[index] = e.target.value;
                      setReturnSalesFields(newReturnSalesFields);
                    }}
                    defaultValue={returnSalesFields[index]}
                  />
                  <IconButton
                    size="small"
                    onClick={() => {
                      removeCFOPReturnSale(index);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              ))}
              <IconButton color="primary" onClick={() => addCFOPReturnSale()}>
                <AddIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="return_purchase">CFOP de Devolução Compra</InputLabel>
              {returnPurchaseFields.map((field, index) => (
                <Grid item key={field + index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                  <TextField
                    name={`return_purchase.${index}.value`}
                    placeholder="CFOP de Devolução Compra"
                    size="small"
                    sx={{ width: '100%' }}
                    onChange={(e) => {
                      const newReturnPurchaseFields = [...returnPurchaseFields];
                      newReturnPurchaseFields[index] = e.target.value;
                      setReturnPurchaseFields(newReturnPurchaseFields);
                    }}
                    defaultValue={returnPurchaseFields[index]}
                  />
                  <IconButton
                    size="small"
                    onClick={() => {
                      removeCFOPReturnPurchase(index);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              ))}
              <IconButton color="primary" onClick={() => addCFOPReturnPurchase()}>
                <AddIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="analytics_accounts_sale">Conta Contábil Venda</InputLabel>
              <ControlledComboBox
                multiple
                name="analytics_accounts_sale"
                control={control}
                errorMessage={errors.analytics_accounts_sale?.message?.toString()}
                selectOptions={analyticsAccounts}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="analytics_accounts_purchase">Conta Contábil Compra</InputLabel>
              <ControlledComboBox
                multiple
                name="analytics_accounts_purchase"
                control={control}
                errorMessage={errors.analytics_accounts_purchase?.message?.toString()}
                selectOptions={analyticsAccounts}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
