import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import { Card, Divider, Grid, Icon, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { CheckProcessingService } from '../../../services/CheckProcessing.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { hexToRgba } from '../../../utils/utils';
import { useAnalyticFormContext } from '../context/AnalyticsContext';
import { AnalyticBPTab } from '../TabsContent/AnalyticBP';
import { AnalyticsBPProvider } from '../TabsContent/AnalyticBP/DynamicTableBP/context/TableContext';
import { AnalyticDRETab } from '../TabsContent/AnalyticDRE';
import { AnalyticsDREProvider } from '../TabsContent/AnalyticDRE/DynamicTableDRE/context/TableContext';
import { AnalyticFcTab } from '../TabsContent/AnalyticFC';
import { AnalyticsFCProvider } from '../TabsContent/AnalyticFC/DynamicTableFC/context/TableContext';
import { AnalyticHomeTab } from '../TabsContent/AnalyticHome';
import { AnalyticNcgTab } from '../TabsContent/AnalyticNCG';
import { AnalyticsNCGProvider } from '../TabsContent/AnalyticNCG/DynamicTableNCG/context/TableContext';
import { AnalyticSoldItemTab } from '../TabsContent/AnalyticSoldItem';
import { SoldItemAnalyticContext } from '../TabsContent/AnalyticSoldItem/TableComponent/context/SoldItemAnalyticContext';

export const AnalyticsPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { currentTab, handleChangeTab } = useAnalyticFormContext();
  const [tabsVisibility, setTabsVisibility] = useState([true, false, false, false, false, false]);

  const tabs = [
    {
      label: 'Análises',
      icon: <HomeIcon fontSize="small" />,
      disabled: false
    },
    {
      label: 'Balanço Patrimonial (BP)',
      icon: null,
      disabled: false
    },
    {
      label: 'Demonstração do Resultado do Exercício (DRE)',
      icon: null,
      disabled: false
    },
    {
      label: 'Necessidade de Capital de Giro (NCG)',
      icon: null,
      disabled: false
    },
    {
      label: 'Fluxo de Caixa (FC)',
      icon: null,
      disabled: false
    },
    {
      label: 'Itens Vendidos',
      icon: null,
      disabled: false
    }
  ];

  const checkEcdFiles = async () => {
    const response = await CheckProcessingService.get();
    if (response?.data?.count === 0) {
      return true;
    } else {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: 'Existe um processo em andamento. Aguarde a finalização para acessar as análises',
          severity: 'error'
        })
      );
      return false;
    }
  };

  const handleCardClick = async (index: number) => {
    if (await checkEcdFiles()) {
      const updatedVisibility = tabsVisibility.map((visibility, i) => (i === index ? true : visibility));
      setTabsVisibility(updatedVisibility);
      handleChangeTab(index);
    }
  };

  const handleCloseTab = (index: number) => {
    const updatedVisibility = tabsVisibility.map((visibility, i) => (i === index ? false : visibility));
    setTabsVisibility(updatedVisibility);
    handleChangeTab(0);
  };

  return (
    <Card elevation={2}>
      <Grid container>
        <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={currentTab}
            scrollButtons={false}
            onChange={(event, newValue) => {
              handleChangeTab(newValue);
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={tab.label + String(index)}
                label={tab.label}
                icon={
                  tab.icon ? (
                    tab.icon
                  ) : (
                    <Icon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCloseTab(index);
                      }}
                      sx={{
                        marginLeft: 'auto'
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Icon>
                  )
                }
                iconPosition="end"
                sx={{
                  height: 70,
                  maxHeight: 30,
                  minHeight: 50,
                  justifyContent: 'center'
                }}
                style={{
                  textTransform: 'none',
                  backgroundColor: currentTab === index ? hexToRgba(theme.palette.primary.main, 0.1) : 'inherit',
                  display: tabsVisibility[index] ? 'flex' : 'none'
                }}
              />
            ))}
          </Tabs>
        </Grid>
        <Divider />
        <Grid
          item
          xs={12}
          sx={{
            display: currentTab === 0 ? 'flex' : 'none'
          }}
        >
          <AnalyticHomeTab onCardClick={handleCardClick} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: currentTab === 1 ? 'flex' : 'none'
          }}
        >
          {tabsVisibility[1] && (
            <AnalyticsBPProvider>
              <AnalyticBPTab />
            </AnalyticsBPProvider>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: currentTab === 2 ? 'flex' : 'none'
          }}
        >
          {tabsVisibility[2] && (
            <AnalyticsDREProvider>
              <AnalyticDRETab />
            </AnalyticsDREProvider>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: currentTab === 3 ? 'flex' : 'none'
          }}
        >
          {tabsVisibility[3] && (
            <AnalyticsNCGProvider>
              <AnalyticNcgTab />
            </AnalyticsNCGProvider>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: currentTab === 4 ? 'flex' : 'none'
          }}
        >
          {tabsVisibility[4] && (
            <AnalyticsFCProvider>
              <AnalyticFcTab />
            </AnalyticsFCProvider>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: currentTab === 5 ? 'flex' : 'none'
          }}
        >
          {tabsVisibility[5] && (
            <SoldItemAnalyticContext>
              <AnalyticSoldItemTab />
            </SoldItemAnalyticContext>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};
