import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { PermissionsObj } from '../../../hooks/useCrudPermissions';
import { ManualInput } from '../../../models/ManualInput';
import { useManualInputsContext } from '../context/ManualInputsContext';
import { ManualInputsTable } from './ManualInputsTable';

interface Props {
  onView: (item: ManualInput) => void;
  onEdit: (item: ManualInput) => void;
  permissions: PermissionsObj;
}

export const ManualInputsTableCard = ({ onView, onEdit, permissions }: Props) => {
  const { manualInputs } = useManualInputsContext();

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        <ManualInputsTable onView={onView} onEdit={onEdit} permissions={permissions} ManualInputs={manualInputs} />
      </Paper>
    </Box>
  );
};
