import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StyledTableRow } from '../../../components/basics/StyledTableRow';
import { HeadCell, Order, TableHeaderComponent } from '../../../components/basics/TableHeaderComponent';
import { PermissionsObj } from '../../../hooks/useCrudPermissionsHook';
import { Company } from '../../../models/Company';
import { CompanyParameters } from '../../../models/CompanyParameters';
import { CompanyParametersService } from '../../../services/CompanyParameters.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { CompanyParametersResult, useCompanyParametersPageContext } from '../context/CompanyParametersPageContext';

const headCells: readonly HeadCell[] = [
  {
    id: 'key',
    align: 'left',
    disablePadding: false,
    disableSorting: false,
    label: 'Chave de Parâmetro'
  },
  {
    id: 'description',
    align: 'left',
    disablePadding: false,
    disableSorting: false,
    label: 'Descrição de Parâmetro'
  },
  {
    id: 'value',
    align: 'left',
    disablePadding: false,
    disableSorting: true,
    label: 'Valor'
  },
  {
    id: 'actions',
    align: 'right',
    disablePadding: false,
    disableSorting: true,
    label: 'Ações'
  }
];

interface Props {
  onEdit: (item: Company) => void;
  permissions: PermissionsObj;
  saved: number;
}

export const CompanyParametersTable = ({ onEdit, permissions, saved }: Props) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Company>('company_name');
  const [selected, setSelected] = useState<string[]>([]);

  const [companyParameters, setCompanyParameters] = React.useState<CompanyParametersResult | undefined>(undefined);

  const dispatch = useDispatch();

  const { filterFields, setFilterFields, page, rowsPerPage, handleOrderChange, handlePageChange, handleOnChangeRowsPerPage } =
    useCompanyParametersPageContext();

  const handleRequestSort = (property: keyof Company) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleOrderChange(property, isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = (selectedAll: boolean) => {
    if (selectedAll) {
      const newSelected = companyParameters?.results.map((item: CompanyParameters) => String(item.id)) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const fetchCompanyParameters = async () => {
    dispatch(showSpinner());
    try {
      const response = await CompanyParametersService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setCompanyParameters(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    setFilterFields(filterFields);
  }, []);

  useEffect(() => {
    fetchCompanyParameters();
  }, [filterFields, page, rowsPerPage, saved]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
            <TableHeaderComponent
              headCells={headCells}
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              rowCount={companyParameters?.count || 0}
              hideCheckbox={true}
            />
            <TableBody>
              {companyParameters && companyParameters?.count > 0 ? (
                <>
                  {companyParameters?.results?.map((row: CompanyParameters, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        onClick={(event) => handleClick(event, String(row.id))}
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="normal" align="left">
                          {row.key}
                        </TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                        <TableCell align="left">{row.value}</TableCell>

                        <TableCell align="right">
                          {permissions.hasUpdatePermission && (
                            <Tooltip title="Editar">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onEdit(row);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : (
                <StyledTableRow>
                  <TableCell align="center" colSpan={6}>
                    Nenhum registro encontrado
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {companyParameters && companyParameters?.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 25, 50]}
            component="div"
            count={companyParameters?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
          />
        )}
      </Paper>
    </Box>
  );
};
