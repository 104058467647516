import { capitalize, indexOf } from 'lodash';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITableColumn } from '../..';
import { IAnalyticsFC, IAnalyticsFCFilter } from '../../../../../../models/AnalyticsFC';
import { AnalysisFCService } from '../../../../../../services/AnalysisFC.service';
import { hideSpinner, showSpinner } from '../../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../../../../store/store';
import { checkResponseStatus } from '../../../../../../utils/api/response';
import { formatColumnNameIntoDate } from '../../../../../../utils/utils';
import { ManualInputsModal } from '../../../../components/ManualInputsModal';
import { ChartModal } from '../ChartModal';
import { DetailsModal } from '../DetailsModal';
import { ManualInputModal } from '../ManualInputModal';
import { MinimizedModalBar } from '../MinimizedModalBar';

// Definindo a interface para o estado de abertura dos níveis
interface OpenLevels {
  [level: number]: boolean;
}

export interface IAnalysisFCModal {
  title: string;
  visible: boolean;
  type: 'details' | 'chart' | 'manual_inputs';
  data: any;
  fullscreen?: boolean;
}

// Definindo a interface para o contexto
interface ContextData {
  openLevels: OpenLevels;
  toggleLevel: (level: number) => void;
  handleOpenNewDetailsModal: (data: any) => void;
  handleOpenNewManualInputsModal: (data: any) => void;
  handleNewManualInput: (data: any) => void;
  filters: IAnalyticsFCFilter[];
  handleSetFilters: (filters: IAnalyticsFCFilter[]) => void;
  data: IAnalyticsFC;
  columns: ITableColumn[];
  toggleColumnVisibility: (columnId: string) => void;
  toggleModalFullscreen: (modal: IAnalysisFCModal) => void;
}

// Criação do Context
const AnalyticsFCTableContext = createContext<ContextData | undefined>(undefined);

// Definindo a interface para o provider
interface ProviderProps {
  children: ReactNode;
}

// Componente Provider
export function AnalyticsFCProvider({ children }: ProviderProps) {
  const dispatch = useDispatch();
  const { companiesIds, loading } = useSelector((state: RootState) => state.companiesContext);

  const [modalList, setModalList] = useState<IAnalysisFCModal[]>([]);
  const [manualInputModal, setManualInputModal] = useState<any | null>(null);

  const [filters, setFilters] = useState<IAnalyticsFCFilter[]>({} as IAnalyticsFCFilter[]);
  const [data, setData] = useState<IAnalyticsFC>({} as IAnalyticsFC);
  const [columns, setColumns] = useState<ITableColumn[]>([]);

  const VISIBLE_MODAL = modalList.filter((modal) => modal.visible);
  const MINIMIZED_MODALS = modalList.filter((modal) => !modal.visible);

  const [openLevels, setOpenLevels] = useState<OpenLevels>({
    1: true,
    2: false,
    3: false
  });

  const toggleLevel = (level: number) => {
    setOpenLevels((prevLevels) => ({
      ...prevLevels,
      [level]: !prevLevels[level]
    }));
  };

  const handleOpenNewDetailsModal = (data: any) => {
    //adicionar um novo modal a lista
    const newModal = {
      title: formatColumnNameIntoDate(data.key) + ' - ' + capitalize(data.row.description) + ' - ' + data.columnLabel,
      visible: true,
      fullScreen: false,
      type: 'details',
      data: data
    };

    setModalList([...modalList, newModal]);
  };

  const handleOpenNewManualInputsModal = (data: any) => {
    const newModal = {
      title: formatColumnNameIntoDate(data.key) + ' - ' + capitalize(data.row.description) + ' - ' + data.columnLabel,
      visible: true,
      fullScreen: false,
      type: 'manual_inputs',
      data: data
    };

    setModalList([...modalList, newModal]);
  };

  const handleCloseModal = (modal: any) => {
    const index = indexOf(modalList, modal);
    if (index > -1) {
      modalList.splice(index, 1);
    }
    setModalList([...modalList]);
  };

  const handleDisplayModal = (modal?: any) => {
    //abrir o modal clicado
    const index = indexOf(modalList, modal);
    let newList = modalList.map((modal, i) => {
      if (i === index) {
        modal.visible = true;
      } else {
        modal.visible = false;
      }
      return modal;
    });
    setModalList([...newList]);
  };

  const handleMinimizeAll = () => {
    let newList = modalList.map((modal) => {
      modal.visible = false;
      return modal;
    });
    setModalList([...newList]);
    return;
  };

  const handleNewManualInput = (data: any) => {
    setManualInputModal(data);
  };

  const handleCloseManualInput = () => {
    setManualInputModal(undefined);
  };

  const toggleColumnVisibility = (columnId: string) => {
    setColumns((prevColumns) => prevColumns.map((column) => (column.id === columnId ? { ...column, visible: !column.visible } : column)));
  };

  const toggleModalFullscreen = (modal: IAnalysisFCModal) => {
    const index = indexOf(modalList, modal);
    if (index > -1) {
      modalList[index].fullscreen = !modalList[index].fullscreen;
    }
    setModalList([...modalList]);
  };

  const handleSetFilters = (filters: IAnalyticsFCFilter[]) => {
    setFilters(filters);
  };

  const createTableColumns = (data: any) => {
    if (!data.metadata.columns) {
      dispatch(
        showSnackbarAlert({
          title: 'Ocorreu um erro',
          message: 'Não foi possível carregar os dados da análise de balanço patrimonial',
          severity: 'error'
        })
      );
      return;
    }

    const newColumns = data.metadata.columns.map((key: string, index: number) => ({
      id: key,
      name: formatColumnNameIntoDate(key),
      visible: true,
      index: index,
      year: key.split('-')[0] || ''
    }));
    setColumns(newColumns);
  };

  const fetchData = async () => {
    dispatch(showSpinner());

    try {
      const response = await AnalysisFCService.post(filters);
      if (response && checkResponseStatus(response)) {
        setData(response?.data);
        createTableColumns(response?.data);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      dispatch(hideSpinner());
    }
  };

  //ouvir as alteracoes do redux e fazer a busca novamente com as novas empresas.
  useEffect(() => {
    if (!loading && companiesIds && companiesIds.length > 0 && Object.entries(filters).length > 0) {
      (async () => {
        await fetchData();
      })();
    }
  }, [companiesIds, filters]);

  return (
    <AnalyticsFCTableContext.Provider
      value={{
        openLevels,
        toggleLevel,
        handleOpenNewDetailsModal,
        handleOpenNewManualInputsModal,
        handleNewManualInput,
        filters,
        handleSetFilters,
        data,
        columns,
        toggleColumnVisibility,
        toggleModalFullscreen
      }}
    >
      <ManualInputModal modalData={manualInputModal} onClose={handleCloseManualInput} />
      {VISIBLE_MODAL?.map((modal) => {
        return modal.type === 'details' ? (
          <DetailsModal
            key={modal.title}
            modalData={modal}
            onClose={() => handleCloseModal(modal)}
            onMinimize={() => handleMinimizeAll()}
            onToggleModalFullscreen={() => toggleModalFullscreen(modal)}
          />
        ) : modal.type === 'manual_inputs' ? (
          <ManualInputsModal
            key={modal.title}
            modalData={modal}
            onClose={() => handleCloseModal(modal)}
            onMinimize={() => handleMinimizeAll()}
            onToggleModalFullscreen={() => toggleModalFullscreen(modal)}
          />
        ) : (
          <ChartModal
            key={modal.title}
            modalData={modal}
            onClose={() => handleCloseModal(modal)}
            onMinimize={() => handleMinimizeAll()}
            onToggleModalFullscreen={() => toggleModalFullscreen(modal)}
          />
        );
      })}
      <MinimizedModalBar
        modalList={MINIMIZED_MODALS}
        onDisplayModal={(modal) => handleDisplayModal(modal)}
        onCloseModal={(modal) => handleCloseModal(modal)}
      />
      {children}
    </AnalyticsFCTableContext.Provider>
  );
}

// Hook personalizado para usar o Level Context
export function useAnalyticsFCTableContext(): ContextData {
  const context = useContext(AnalyticsFCTableContext);
  if (!context) {
    throw new Error('useAnalyticsFCTableContext must be used within a AnalyticsFCProvider');
  }
  return context;
}
