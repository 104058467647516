import { Box, Card, CardHeader, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DialogComponent } from '../../../../components/basics/DialogComponent';
import { RootState } from '../../../../store/store';

interface AnalyticHomeTabProps {
  onCardClick: (index: number) => void;
}

export const AnalyticHomeTab = ({ onCardClick }: AnalyticHomeTabProps) => {
  const { companiesIds } = useSelector((state: RootState) => state.companiesContext);
  const [contextModal, setContextModal] = useState(false);

  const CARDS = [
    {
      title: 'Balanço Patrimonial (BP)'
    },
    {
      title: 'Demonstrativo de Resultado do Exercício (DRE)'
    },
    {
      title: 'Necessidade de Capital de Giro (NCG)'
    },
    {
      title: 'Fluxo de Caixa (FC)'
    },
    {
      title: 'Itens Vendidos'
    }
  ];

  const handleOnClick = (index: number) => {
    if (companiesIds.length == 0) {
      setContextModal(true);
      return;
    }
    onCardClick(index);
  };

  return (
    <Box p={2} sx={{ height: '100%', width: '100%' }}>
      <Typography variant="h4" component="div">
        Análises
      </Typography>
      <Typography variant="body1" component="div" gutterBottom>
        Selecione o relatório que deseja visualizar
      </Typography>

      <Grid spacing={2} container mt={2} mb={2} sx={{ display: 'flex', flex: 1 }}>
        {CARDS.map((card, index) => (
          <Grid item xs={12} md={3} sx={{ display: 'flex', flex: 1, cursor: 'pointer' }} onClick={() => handleOnClick(index + 1)}>
            <Card elevation={3} sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <CardHeader title={card.title} subheader="Clique para abrir" />
            </Card>
          </Grid>
        ))}
      </Grid>

      <DialogComponent open={contextModal} title="Atenção!" onCancel={() => setContextModal(false)}>
        <Typography variant="body1" component="div" gutterBottom>
          Você deve selecionar uma empresa para visualizar os relatórios de análise
        </Typography>
      </DialogComponent>
    </Box>
  );
};
