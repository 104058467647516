import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { IAnalyticsFCRow } from '../../../../../../models/AnalyticsFC';
import { TableRowRecursive } from '../TableRowRecursive';
import { useAnalyticsFCTableContext } from '../context/TableContext';
import './DynamicTable.css';

export default function DynamicTableFC() {
  const { data, columns, toggleColumnVisibility } = useAnalyticsFCTableContext();
  const [tableHeight, setTableHeight] = useState(window.innerHeight - 220);

  useEffect(() => {
    const handleResize = () => {
      setTableHeight(window.innerHeight - 220);
    };

    window.addEventListener('resize', handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <TableContainer style={{ overflow: 'auto', maxHeight: `${tableHeight}px` }}>
      <Table stickyHeader aria-label="sticky table" size="small">
        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
          <TableRow>
            <TableCell key="empty-cell" sx={{ zIndex: 999, position: 'sticky', left: 0 }} /> {/* empty cell equivalente a descrição */}
            {columns.map(
              (column, index) =>
                column?.visible && (
                  <TableCell key={`${column.id}-header-${index}`} colSpan={column.id === 'description' ? 0 : 1}>
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="subtitle1" textAlign={column.id === 'description' ? 'left' : 'center'}>
                        {column.name}
                      </Typography>
                      <IconButton onClick={() => toggleColumnVisibility(column.id)}>
                        <RemoveCircleOutlineIcon sx={{ fontSize: 15 }} />
                      </IconButton>
                    </Stack>
                  </TableCell>
                )
            )}
          </TableRow>
          <TableRow>
            <TableCell key="description-empty-cell" rowSpan={2} sx={{ zIndex: 999, position: 'sticky', left: 0 }} />
            {columns.map(
              (column, index) =>
                column.visible && (
                  <Fragment key={`${column.id}-header-${index}`}>
                    <TableCell
                      key={`${column.id}-value-${index}`}
                      align="center"
                      style={{
                        borderRight: '0.5px solid #c7c7c7'
                      }}
                    >
                      <Typography variant="subtitle2">Valor</Typography>
                    </TableCell>
                  </Fragment>
                )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.report?.map((row: IAnalyticsFCRow, index) => (
            <TableRowRecursive
              key={`row-${row.id}-${row.description}-${row.level}-${index}`}
              row={row}
              level={row.level}
              columns={columns}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
