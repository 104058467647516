import { lazy } from 'react';
import { Loadable } from '../components/basics/LazyLoaderComponent/Loadable';
import MainLayout from '../layout/MainLayout';
import { AnalyticsTypeWrapper } from '../pages/private/AnalyticsTypeWrapper';
import { AnalyticsWrapper } from '../pages/private/AnalyticsWrapper';
import { BudgetWrapper } from '../pages/private/BudgetWrapper';
import { ChartAccountWrapper } from '../pages/private/ChartAccountsWrapper';
import { CompaniesGroupsPageWrapper } from '../pages/private/CompaniesGroups';
import { CompaniesPageWrapper } from '../pages/private/CompaniesPageWrapper';
import { CompaniesTypesPageWrapper } from '../pages/private/CompaniesTypes';
import { CompanyParametersPageWrapper } from '../pages/private/CompanyParameters';
import { ConciliationWrapper } from '../pages/private/ConciliationWrapper';
import { DashboardStructureWrapper } from '../pages/private/DashboardStructureWrapper';
import { ManualInputsPageWrapper } from '../pages/private/ManualInputsWrapper';
import { PermissionGroupsPageWrapper } from '../pages/private/PermissionGroups';
import { ProcessingWrapper } from '../pages/private/ProcessingWrapper';
import { ProfilePageWrapper } from '../pages/private/ProfilePageWrapper';
import { SoldItemsPageWrapper } from '../pages/private/SoldItemsWrapper';
import { UsersPageWrapper } from '../pages/private/UsersPageWrapper';
import { ROUTES } from './routes';

const DashboardDefault = Loadable(lazy(() => import('../pages/private/DashboardPageWrapper')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: ROUTES.authenticated.dashboard.home,
      element: <DashboardDefault />
    },
    {
      path: ROUTES.authenticated.organizations.companies,
      element: <CompaniesPageWrapper />
    },
    {
      path: ROUTES.authenticated.organizations.companiesGroups,
      element: <CompaniesGroupsPageWrapper />
    },
    {
      path: ROUTES.authenticated.organizations.companiesTypes,
      element: <CompaniesTypesPageWrapper />
    },
    {
      path: ROUTES.authenticated.user.profile,
      element: <ProfilePageWrapper />
    },
    {
      path: ROUTES.authenticated.access.users,
      element: <UsersPageWrapper />
    },
    {
      path: ROUTES.authenticated.access.permissionGroups,
      element: <PermissionGroupsPageWrapper />
    },
    {
      path: ROUTES.authenticated.analytics.analytics,
      element: <AnalyticsWrapper />
    },
    {
      path: ROUTES.authenticated.analytics.analyticType,
      element: <AnalyticsTypeWrapper />
    },
    {
      path: ROUTES.authenticated.analytics.chartAccount,
      element: <ChartAccountWrapper />
    },
    {
      path: ROUTES.authenticated.accountingEntries.spedImport,
      element: <ProcessingWrapper />
    },
    {
      path: ROUTES.authenticated.conciliation.conciliation,
      element: <ConciliationWrapper />
    },
    {
      path: ROUTES.authenticated.settings.dashboardStructure,
      element: <DashboardStructureWrapper />
    },
    {
      path: ROUTES.authenticated.accountingEntries.manualInputs,
      element: <ManualInputsPageWrapper />
    },
    {
      path: ROUTES.authenticated.accountingEntries.soldItems,
      element: <SoldItemsPageWrapper />
    },
    {
      path: ROUTES.authenticated.accountingEntries.budget,
      element: <BudgetWrapper />
    },
    {
      path: ROUTES.authenticated.auxiliary.parameters,
      element: <CompanyParametersPageWrapper />
    }
  ]
};

export default MainRoutes;
