import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
export interface BudgetItemDREFilterFields {
  budget_id?: number;
}

const BudgetItemDREUrl = '/reports/budget/';

export const BudgetItemDREService = {
  get: async (filterFields: BudgetItemDREFilterFields) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(`${BudgetItemDREUrl}${filterFields.budget_id}`, {
        params: {
          ...filterFields
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
