import { DialogWithToggleFullscreen } from '../../../../../../components/basics/DialogWithToggleFullscreen';
import { IAnalysisBpModal } from '../context/TableContext';
import { GraphComponentBp } from './GraphComponentBp';

interface Props {
  modalData: IAnalysisBpModal;
  onClose: () => void;
  onMinimize: () => void;
  onToggleModalFullscreen: () => void;
}

export const ChartModal = ({ modalData, onClose, onMinimize, onToggleModalFullscreen }: Props) => {
  return (
    <DialogWithToggleFullscreen
      open={!!modalData}
      onClose={onClose}
      title="Gráfico"
      onMinimize={onMinimize}
      fullscreen={modalData.fullscreen}
      toggleFullscreen={onToggleModalFullscreen}
    >
      <GraphComponentBp />
    </DialogWithToggleFullscreen>
  );
};
