import { ManualInputsPage } from '../../../sections/@manual-inputs/ManualInputsPage';
import { ManualInputsContext } from '../../../sections/@manual-inputs/context/ManualInputsContext';

export const ManualInputsPageWrapper = () => {
  return (
    <ManualInputsContext filterRequired={false}>
      <ManualInputsPage />
    </ManualInputsContext>
  );
};
