import { AccountingEntry } from '../../../../models/AccountingEntry';
import { ApiResults } from '../../../../models/ApiResults';
import { ManualInputsContext } from '../../../@manual-inputs/context/ManualInputsContext';
import { IAnalysisBpModal } from '../../TabsContent/AnalyticBP/DynamicTableBP/context/TableContext';
import { ManualInputsTableModal } from './ManualInputsTableModal';

interface Props {
  modalData: IAnalysisBpModal;
  onClose: () => void;
  onMinimize: () => void;
  onToggleModalFullscreen: () => void;
}

export interface AccountingEntryFilterFields extends AccountingEntry {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}
export interface AccountEntryResult extends ApiResults {
  results: {
    metadata: {
      previous_balance: string;
      total_credit: string;
      total_debit: string;
    };
    report: AccountingEntry[];
  };
}

export const ManualInputsModal = ({ modalData, onClose, onMinimize, onToggleModalFullscreen }: Props) => {
  return (
    <ManualInputsContext filterRequired>
      <ManualInputsTableModal
        key={modalData.title}
        modalData={modalData}
        onClose={onClose}
        onMinimize={onMinimize}
        onToggleModalFullscreen={onToggleModalFullscreen}
      />
    </ManualInputsContext>
  );
};
