import { ExpandLess, ExpandMore } from '@mui/icons-material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Box, Button, DialogContent, Icon, Popover, Stack, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCrudPermissions } from '../../../../../../hooks/useCrudPermissions';
import { useAnalyticsBPTableContext } from '../context/TableContext';

interface ITableRowProps {
  row: any;
  level?: number;
}

export const TableRowRecursive = ({ row, level = 1 }: ITableRowProps) => {
  const theme = useTheme();
  const { permissions: permissions_manual_input } = useCrudPermissions({ submodule: 'MANUAL_ACCOUNTING_ENTRY' });
  const [rightClickAnchorEl, setRightClickAnchorEl] = useState<null | HTMLElement>(null);
  const [righClickRow, setRightClickRow] = useState<any>({});

  const [rowOpen, setRowOpen] = useState<boolean>(false);
  const { openLevels, handleOpenNewDetailsModal, handleNewManualInput, handleOpenNewManualInputsModal, columns } =
    useAnalyticsBPTableContext();
  const levelOpen = openLevels[level + 1];

  const paddingValue = `${(row.level - 1) * 40 || (level - 1) * 40}px`;
  const fontWeight = row.level == 1 ? '700' : '400';

  const toggleOpen = () => {
    setRowOpen(!rowOpen);
  };

  const handleRightClick = ({
    event,
    row,
    key,
    level,
    columnLabel,
    type
  }: {
    event: any;
    row: any;
    key: string;
    level: number;
    columnLabel: string;
    type: string;
  }) => {
    event.preventDefault();
    setRightClickRow({ row, key, level, columnLabel, type });
    setRightClickAnchorEl(event.currentTarget);
  };

  const handleRightClickClose = () => {
    setRightClickAnchorEl(null);
  };

  const handleClickDetailsButton = () => {
    setRightClickAnchorEl(null);
    handleOpenNewDetailsModal(righClickRow);
  };

  const handleClickManualInputsButton = () => {
    setRightClickAnchorEl(null);
    handleOpenNewManualInputsModal(righClickRow);
  };

  const handleClickNewManualInput = () => {
    setRightClickAnchorEl(null);
    handleNewManualInput(righClickRow);
  };

  //abrir e fechar os niveis individuais de acordo com o estado do nivel
  useEffect(() => {
    if (openLevels[level + 1] === false) {
      setRowOpen(false);
    } else {
      setRowOpen(true);
    }
  }, [openLevels]);

  return (
    <>
      <TableRow key={`row-${row.description}-${level}-${row?.children?.length}`}>
        <div style={{ backgroundColor: 'white', padding: 0, position: 'sticky', left: 0, width: '300px' }}>
          <TableCell sx={{ minWidth: '300px', position: 'sticky', left: 0, zIndex: 2 }} className="labelTableCell">
            <Stack direction="row" spacing={1} sx={{ paddingLeft: paddingValue, alignItems: 'center', zIndex: 2 }}>
              {row?.children?.length > 0 && (
                <Icon onClick={() => toggleOpen()} component={Box} sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                  {rowOpen ? <ExpandLess /> : <ExpandMore />}
                </Icon>
              )}
              <Typography variant="subtitle2">{row.description}</Typography>
            </Stack>
          </TableCell>
        </div>
        {columns.map((column, index) => {
          return (
            row.totals &&
            column.visible && (
              <>
                <TableCell
                  onContextMenu={(event) => {
                    if (level === 4 && permissions_manual_input.hasReadPermission) {
                      handleRightClick({
                        event,
                        row,
                        key: column.id,
                        level,
                        columnLabel: 'Acumulado Manual',
                        type: 'accumulated-manual'
                      });
                    }
                  }}
                  key={`${column.id}-${index}-accumulated-manual`}
                  align="right"
                  sx={{
                    cursor: 'pointer',
                    ':hover': {
                      transform: 'scale(1.2)',
                      transition: '0.3s'
                    }
                  }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight }}>
                    {row.totals[column.id] ? row.totals[column.id].accumulated_month_manual : 0}
                  </Typography>
                </TableCell>
                <TableCell
                  onContextMenu={(event) =>
                    handleRightClick({
                      event,
                      row,
                      key: column.id,
                      level,
                      columnLabel: 'Acumulado',
                      type: 'accumulated-total'
                    })
                  }
                  key={`${column.id}-${index}-accumulated-total`}
                  align="right"
                  sx={{
                    backgroundColor: row.totals[column.id]?.background_colors?.accumulated_total,
                    cursor: 'pointer',
                    ':hover': {
                      transform: 'scale(1.2)',
                      transition: '0.3s'
                    }
                  }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight }}>
                    {row.totals[column.id] ? row.totals[column.id].accumulated_total : 0}
                  </Typography>
                </TableCell>
                <TableCell
                  key={`${column.id}-${index}-percent-total`}
                  align="right"
                  sx={{
                    backgroundColor: row.totals[column.id]?.background_colors?.percent_total,
                    cursor: 'pointer',
                    ':hover': {
                      transform: 'scale(1.2)',
                      transition: '0.3s'
                    }
                  }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight }}>
                    {row.totals[column.id] ? row.totals[column.id].percent_total : 0}
                  </Typography>
                </TableCell>
                <TableCell
                  onContextMenu={(event) =>
                    handleRightClick({
                      event,
                      row,
                      key: column.id,
                      level,
                      columnLabel: 'Valor',
                      type: 'value'
                    })
                  }
                  key={`${column.id}-${index}-value`}
                  align="right"
                  sx={{
                    backgroundColor: row.totals[column.id]?.background_colors?.accumulated_month,
                    cursor: 'pointer',
                    ':hover': {
                      transform: 'scale(1.2)',
                      transition: '0.3s'
                    }
                  }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight }}>
                    {row.totals[column.id] ? row.totals[column.id].accumulated_month : 0}
                  </Typography>
                </TableCell>
                <TableCell
                  onContextMenu={(event) =>
                    handleRightClick({
                      event,
                      row,
                      key: column.id,
                      level,
                      columnLabel: 'Acumulado Ano',
                      type: 'accumulated-year'
                    })
                  }
                  key={`${column.id}-${index}-accumulated-year`}
                  align="right"
                  sx={{
                    borderRight: '0.5px solid #c7c7c7',
                    backgroundColor: row.totals[column.id]?.background_colors?.accumulated_year,
                    cursor: 'pointer',
                    ':hover': {
                      transform: 'scale(1.2)',
                      transition: '0.3s'
                    }
                  }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight }}>
                    {row.totals[column.id] ? row.totals[column.id].accumulated_year : 0}
                  </Typography>
                </TableCell>
              </>
            )
          );
        })}
      </TableRow>
      {((row.children?.length > 0 && rowOpen) || (levelOpen && rowOpen)) &&
        row.children.map((item: any, index: number) => (
          <TableRowRecursive key={`${item.description}-${index}-${level + 1}`} row={item} level={level + 1} />
        ))}

      {/* Popover que aparece quando clicado com o botao direito em alguma celula da tabela */}
      <Popover
        open={!!rightClickAnchorEl}
        anchorEl={rightClickAnchorEl}
        onClose={handleRightClickClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        sx={{ mt: 2 }}
      >
        <DialogContent sx={{ p: 0.5 }}>
          <Stack sx={{ display: 'flex', alignItems: 'flex-start' }}>
            {righClickRow.level == 4 && (
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={handleClickManualInputsButton}
                startIcon={<FormatListBulletedIcon />}
              >
                Lançamentos Manuais
              </Button>
            )}
            <Button variant="text" color="primary" size="small" onClick={handleClickDetailsButton} startIcon={<FormatListBulletedIcon />}>
              Detalhamento
            </Button>
          </Stack>
        </DialogContent>
      </Popover>
    </>
  );
};
