import { Box, Button } from '@mui/material';

interface Props {
  currentValue: number[];
  onChange: (value: number[]) => void;
}

export const TabSemesterSelector = ({ currentValue, onChange }: Props) => {
  const handleChange = (value: number) => {
    if (currentValue.includes(value)) {
      onChange(currentValue.filter((semester) => semester !== value));
    } else {
      onChange([...currentValue, value]);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2, alignItems: 'center' }}>
      <Button variant={currentValue.includes(1) ? 'contained' : 'outlined'} color="primary" onClick={() => handleChange(1)}>
        1º Semestre
      </Button>
      <Button variant={currentValue.includes(2) ? 'contained' : 'outlined'} color="primary" onClick={() => handleChange(2)}>
        2º Semestre
      </Button>
    </Box>
  );
};
