import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../../../components/basics/ControlledTextInput';
import { BudgetItemReplicate } from '../../../../../models/BudgetItem';
import { BudgetItemService } from '../../../../../services/BudgetItem.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { BudgetItemReplicateScheme } from '../../../../../utils/forms/validations/formValidations';
import { findSelectedOption, monthMapping, setInputErrorsFromApi } from '../../../../../utils/utils';

interface Props {
  onClose: () => void;
  item: BudgetItemReplicate;
  onReplicate: (selectedMonths: string[], value: string, row: BudgetItemReplicate) => void;
}
const months: SelectOption[] = [
  { id: 1, name: 'Janeiro' },
  { id: 2, name: 'Fevereiro' },
  { id: 3, name: 'Março' },
  { id: 4, name: 'Abril' },
  { id: 5, name: 'Maio' },
  { id: 6, name: 'Junho' },
  { id: 7, name: 'Julho' },
  { id: 8, name: 'Agosto' },
  { id: 9, name: 'Setembro' },
  { id: 10, name: 'Outubro' },
  { id: 11, name: 'Novembro' },
  { id: 12, name: 'Dezembro' }
];

export const BudgetItemReplicateDialog = ({ onClose, item, onReplicate }: Props) => {
  const dispatch = useDispatch();
  const filteredMonths = months.filter((month) => month.id !== monthMapping[item?.month]);

  const getDefaultValues = (): BudgetItemReplicate => {
    return {
      month_origin: item?.month ? findSelectedOption(months, monthMapping[item?.month]) : null,
      value: item?.value ?? ''
    };
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<BudgetItemReplicate>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(BudgetItemReplicateScheme),
    defaultValues: getDefaultValues()
  });

  const replicateValues: SubmitHandler<BudgetItemReplicate> = async (data: BudgetItemReplicate) => {
    dispatch(showSpinner());
    try {
      const monthsIdsList = data?.months_destiny?.map((month) => month.id);
      let payload = {
        budget: item?.budget,
        month_origin: data?.month_origin?.id,
        months_destiny: monthsIdsList,
        value: data?.value,
        readjustment: data?.readjustment ? data?.readjustment.replace('%', '').replace(',', '.') : 0
      };
      if (item?.row?.level === 4) {
        payload = {
          ...payload,
          account: item?.row?.parent_id || item?.row?.account?.id,
          cost_center: item?.row?.id
        };
      } else {
        payload = {
          ...payload,
          account: item?.row?.id
        };
      }
      const response = await BudgetItemService.createMany(payload);
      if (response) {
        const months = response.data.map((month) => month.month);
        const valueReadjusted = response.data[0].value;

        onReplicate(months, valueReadjusted, item?.row);
      }

      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Replicar Valores - {item?.row?.description}
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(replicateValues)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="month_origin">Mês Origem</InputLabel>
              <ControlledComboBox
                placeholder="Mês Origem"
                name="month_origin"
                control={control}
                errorMessage={errors.month_origin?.message?.toString()}
                selectOptions={months}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="months_destiny">Meses Destino</InputLabel>
              <ControlledComboBox
                placeholder="Meses Destino"
                name="months_destiny"
                multiple={true}
                control={control}
                errorMessage={errors.months_destiny?.message?.toString()}
                selectOptions={filteredMonths}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="value">Valor Replicado</InputLabel>
              <ControlledTextInput
                name="value"
                placeholder="Valor Replicado"
                control={control}
                disabled={true}
                errorMessage={errors.value?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputLabel htmlFor="readjustment">Reajuste</InputLabel>
              <ControlledTextInput
                name="readjustment"
                control={control}
                percentage={true}
                errorMessage={errors.readjustment?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<ReplyAllIcon />} variant="contained" type="submit">
            Replicar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
