import { Box, Button, Stack } from '@mui/material';
import dayjs from 'dayjs';

interface Props {
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  setStartDate: (date: dayjs.Dayjs | null) => void;
  setEndDate: (date: dayjs.Dayjs | null) => void;
}

export const TabMonthRangeSelector = ({ startDate, endDate, setStartDate, setEndDate }: Props) => {
  const months = Array.from({ length: 12 }, (_, index) => {
    return {
      value: index + 1,
      label: dayjs().locale('pt-Br').month(index).format('MMMM')
    };
  });

  const handleMonthClick = (value: number) => {
    if (!startDate) {
      setStartDate(
        dayjs()
          .month(value - 1)
          .startOf('month')
      );
    }

    if (startDate && !endDate) {
      setEndDate(
        dayjs()
          .month(value - 1)
          .endOf('month')
      );
    }

    if (startDate && endDate) {
      setStartDate(
        dayjs()
          .month(value - 1)
          .startOf('month')
      );
      setEndDate(null);
    }

    //se a data final for menor que a data inicial, trocar as datas
    if (startDate && startDate.month() > value - 1) {
      setEndDate(startDate);
      setStartDate(
        dayjs()
          .month(value - 1)
          .startOf('month')
      );
    }

    if (startDate && endDate) {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const checkMonthInRange = (month: number, startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null) => {
    if (!startDate) {
      return false;
    }

    if (startDate.month() + 1 <= month && month <= Number(endDate?.month()) + 1) {
      return true;
    }

    if (startDate.month() + 1 === month || month === Number(endDate?.month()) + 1) {
      return true;
    }

    return false;
  };

  return (
    <Stack spacing={2}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 1 }}>
        {months?.slice(0, 6).map((month, index) => (
          <Button
            variant={checkMonthInRange(month.value, startDate, endDate) ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => handleMonthClick(month.value)}
            key={index}
          >
            {month.label}
          </Button>
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 1 }}>
        {months?.slice(6, 12).map((month, index) => (
          <Button
            variant={checkMonthInRange(month.value, startDate, endDate) ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => handleMonthClick(month.value)}
            key={index}
          >
            {month.label}
          </Button>
        ))}
      </Box>
    </Stack>
  );
};
