import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IAnalyticsDREBudget } from '../../../models/AnalyticsDRE';
import { Budget } from '../../../models/Budget';
import { BudgetItemDREService } from '../../../services/BudgetItemDRE.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import BudgetItemTable from './BudgetItemTable';

interface Props {
  editItem?: Budget;
  onClose: () => void;
}

export const BudgetItemDialog = ({ editItem, onClose }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [reportDreData, setReportDreData] = useState<IAnalyticsDREBudget>({} as IAnalyticsDREBudget);

  const fetchData = async () => {
    try {
      const response = await BudgetItemDREService.get({ budget_id: editItem?.id });
      if (response && checkResponseStatus(response)) {
        setReportDreData(response?.data);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      dispatch(showSpinner());
      setLoading(true);
      await fetchData();
      setLoading(false);
      dispatch(hideSpinner());
    })();
  }, []);

  return (
    <Dialog open={!loading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullScreen>
      {/* Header */}
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
        <DialogTitle variant="h4" color="primary">
          Orçamento: {editItem?.company?.company_name} - {editItem?.reference_year}
        </DialogTitle>
        <Button variant="text" color="primary" onClick={onClose} startIcon={<CloseIcon />}>
          Fechar
        </Button>
      </Box>
      <Divider />
      <DialogContent sx={{ flex: 1, backgroundColor: theme.palette.background.default }}>
        <BudgetItemTable item={editItem!} reportDreData={reportDreData} />
      </DialogContent>
      <Divider />
    </Dialog>
  );
};
