import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Button, DialogContent, Popover, Stack, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IAnalyticsDRERowBudget } from '../../../../../models/AnalyticsDRE';
import { Budget } from '../../../../../models/Budget';
import { BudgetItemReplicate } from '../../../../../models/BudgetItem';
import { BudgetItemService } from '../../../../../services/BudgetItem.service';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { monthMapping } from '../../../../../utils/utils';
import { BudgetItemCostCenterDialog } from '../BudgetItemCenterCostDialog';
import { BudgetItemCostCenterFormDialog } from '../BudgetItemCenterCostFormDialog';
import { BudgetItemReplicateDialog } from '../BudgetItemReplicateDialog';

interface TableRowRecursiveProps {
  row: IAnalyticsDRERowBudget;
  onChildChange?: (name: string, value: number) => void;
  budget?: Budget;
}

const TableRowRecursive = ({ row, onChildChange, budget }: TableRowRecursiveProps) => {
  const MAX_LEVEL = budget?.company?.use_cost_center ? 4 : 3;
  const initialCellValues = useMemo(
    () => ({
      retroYearAvg: row.values.year_avg?.replace(/[^\d.-]/g, '') || 0,
      retroYearSemiAnnualAvg: row.values.semi_annual_avg?.replace(/[^\d.-]/g, '') || 0,
      retroYearQuarterlyAvg: row.values.quarterly_avg?.replace(/[^\d.-]/g, '') || 0,
      retroYearMonthlyAvg: row.values.monthly_avg?.replace(/[^\d.-]/g, '') || 0,
      january: row.values.january || 0,
      february: row.values.february || 0,
      march: row.values.march || 0,
      april: row.values.april || 0,
      may: row.values.may || 0,
      june: row.values.june || 0,
      july: row.values.july || 0,
      august: row.values.august || 0,
      september: row.values.september || 0,
      october: row.values.october || 0,
      november: row.values.november || 0,
      december: row.values.december || 0
    }),
    [row.values]
  );
  const dispatch = useDispatch();
  const [cellValues, setCellValues] = useState(initialCellValues);
  const [accumulatedYear, setAccumulatedYear] = useState(0);
  const [previousValue, setPreviousValue] = useState<string>('');
  const [replicateDialog, setReplicateDialog] = useState<BudgetItemReplicate>();
  const [costCenterDialog, setCostCenterDialog] = useState<BudgetItemReplicate>();
  const [newCostCenterDialog, setNewCenterDialog] = useState<BudgetItemReplicate>();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [contextInfo, setContextInfo] = useState<{ value: string; column: string; row: IAnalyticsDRERowBudget } | null>(null);
  const [editingCell, setEditingCell] = useState<string | null>(null);

  const handleContextMenu = (event: React.MouseEvent<HTMLElement>, value: string, column: string) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setContextInfo({ value, column, row });
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setContextInfo(null);
  };

  const handleReplicateValues = () => {
    if (contextInfo) {
      const { value, column, row } = contextInfo;
      handleClosePopover();
      const data = {
        month: column,
        value: value,
        row: row,
        budget: budget?.id
      };
      setReplicateDialog(data);
    }
  };
  const handleAddCostCenter = () => {
    if (contextInfo) {
      const { value, column, row } = contextInfo;
      handleClosePopover();
      const data = {
        month: column,
        value: value,
        row: row,
        budget: budget?.id
      };
      setCostCenterDialog(data);
    }
  };
  const handleAddNewCostCenter = () => {
    if (contextInfo) {
      const { value, column, row } = contextInfo;
      handleClosePopover();
      const data = {
        month: column,
        value: value,
        row: row,
        budget: budget?.id
      };
      setNewCenterDialog(data);
    }
  };

  const handleCloseDialogs = () => {
    setReplicateDialog(undefined);
    setCostCenterDialog(undefined);
    setNewCenterDialog(undefined);
  };

  const addLineCostCenter = (newItem: IAnalyticsDRERowBudget) => {
    const addItemRecursively = (currentRow: IAnalyticsDRERowBudget) => {
      if (currentRow.level === 3 && MAX_LEVEL === 4) {
        if (!currentRow.children) {
          currentRow.children = [];
        }
        currentRow.children.push(newItem);
        return true;
      }
      if (currentRow.children) {
        for (let child of currentRow.children) {
          if (addItemRecursively(child)) {
            return true;
          }
        }
      }
      return false;
    };

    const updatedRow = { ...row };

    if (addItemRecursively(updatedRow)) {
      setCellValues((prevValues) => ({
        ...prevValues,
        [updatedRow.id]: updatedRow
      }));
    } else {
      console.warn('Não foi possível adicionar o centro de custo');
    }
  };

  const replicateValuesToSelectedMonths = (selectedMonths: number[], value: string, row: IAnalyticsDRERowBudget) => {
    const monthKeys = {
      1: 'january',
      2: 'february',
      3: 'march',
      4: 'april',
      5: 'may',
      6: 'june',
      7: 'july',
      8: 'august',
      9: 'september',
      10: 'october',
      11: 'november',
      12: 'december'
    };
    selectedMonths.forEach((monthId) => {
      const monthKey = monthKeys[monthId];
      setCellValues((prevValues) => {
        const oldNumericValue = parseFloat(prevValues[monthKey]) || 0;

        const updatedValues = {
          ...prevValues,
          [monthKey]: parseFloat(value)
        };

        if (onChildChange) {
          const childSum = row.children ? row.children.reduce((sum, childRow) => sum + (childRow.values[monthKey] || 0), 0) : 0;
          const parentValue = childSum - oldNumericValue + parseFloat(value);
          onChildChange(monthKey, parentValue);
        }

        return updatedValues;
      });
    });
  };

  const updateAccumulatedYear = useCallback(() => {
    const whiteList = new Set(['retroYearAvg', 'retroYearSemiAnnualAvg', 'retroYearQuarterlyAvg', 'retroYearMonthlyAvg']);

    const accumulatedYearValue = Object.entries(cellValues)
      .filter(([key]) => !whiteList.has(key))
      .reduce((sum, [, val]) => sum + (parseFloat(val.toString().replace(',', '.')) || 0), 0);

    setAccumulatedYear(accumulatedYearValue);
  }, [cellValues]);

  useEffect(() => {
    updateAccumulatedYear();
  }, [cellValues, updateAccumulatedYear]);

  const handleFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    setPreviousValue(event.target.value);
  }, []);

  const handleBlur = useCallback(
    async (event: React.FocusEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      const fieldName = event.target.name;
      let payload = {};
      setEditingCell(null);
      if (inputValue === previousValue) {
        return;
      }

      const month = monthMapping[fieldName];
      if (row?.level === 4) {
        payload = {
          budget: budget?.id,
          month: month,
          account: row.parent_id ?? row?.account?.id,
          cost_center: row.id,
          value: inputValue.replace(',', '.')
        };
      } else {
        payload = {
          budget: budget?.id,
          month: month,
          account: row.id,
          value: inputValue.replace(',', '.')
        };
      }

      try {
        const response = await BudgetItemService.create(payload);
        dispatch(showSnackbarAlert({ title: 'Sucesso', message: 'Valor salvo com sucesso', severity: 'success' }));
      } catch (error) {
        dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao salvar valor', severity: 'error' }));
        setCellValues((prevValues) => ({
          ...prevValues,
          [fieldName]: previousValue
        }));
        event.target.value = previousValue;
      }
    },
    [budget?.id, previousValue, row.id, dispatch]
  );

  const handleCellChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const newNumericValue = parseFloat(value.replace(',', '.')) || 0;

      setCellValues((prevValues) => {
        const oldNumericValue = parseFloat(prevValues[name]) || 0;

        const updatedValues = {
          ...prevValues,
          [name]: newNumericValue
        };

        if (onChildChange) {
          const childSum = row.children ? row.children.reduce((sum, childRow) => sum + (childRow.values[name] || 0), 0) : 0;
          const parentValue = childSum - oldNumericValue + newNumericValue;
          onChildChange(name, parentValue);
        }

        return updatedValues;
      });
      if (value.endsWith(',')) {
        setCellValues((prevValues) => ({
          ...prevValues,
          [name]: value
        }));
      }
    },
    [onChildChange, row.children]
  );

  const handleChildChange = useCallback(
    (name: string, value: number, replace = false) => {
      setCellValues((prevValues) => {
        const updatedValue = replace ? value : prevValues[name] + value;
        return {
          ...prevValues,
          [name]: updatedValue
        };
      });

      if (onChildChange) {
        onChildChange(name, value);
      }
    },
    [onChildChange]
  );

  useEffect(() => {
    setCellValues(initialCellValues);
  }, [row.children, initialCellValues]);

  const renderTextField = useCallback(
    (name: string, value: string) =>
      row.level === MAX_LEVEL && editingCell ? (
        <TableCell align="center" onContextMenu={(e) => handleContextMenu(e, value, name)} sx={{ p: 0 }}>
          <TextField
            name={name}
            sx={{
              minWidth: 40,
              fontSize: '1rem',
              '& .MuiInputBase-input': {
                padding: '4px 0',
                textAlign: 'center',
                fontSize: '1rem'
              },
              '& .MuiInput-underline:before': {
                borderBottom: 'none'
              },
              '& .MuiInput-underline:after': {
                borderBottom: '1px dotted #000'
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: '1px dotted #000'
              }
            }}
            variant="standard"
            size="small"
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
            onChange={handleCellChange}
          />
        </TableCell>
      ) : (
        <TableCell
          align="center"
          onClick={() => setEditingCell(name)}
          sx={{ p: 0 }}
          onContextMenu={(e) => {
            if (row.level === MAX_LEVEL) {
              handleContextMenu(e, value, name);
            }
          }}
        >
          <Typography
            title={row.level === MAX_LEVEL ? undefined : 'Este campo não é editável'}
            variant="body1"
            sx={{ fontSize: '1rem', fontWeight: row.level === MAX_LEVEL ? 'normal' : 'bold' }}
          >
            {parseFloat(value)}
          </Typography>
        </TableCell>
      ),
    [handleBlur, handleCellChange, handleContextMenu, handleFocus, row.level]
  );

  return (
    <>
      <TableRow sx={{ cursor: 'pointer' }}>
        <TableCell
          onContextMenu={(e) => {
            if (row.level === 3 && MAX_LEVEL === 4) {
              handleContextMenu(e, row.description, 'account');
            }
          }}
          align="left"
          sx={{ pl: row.level * 3, width: 500, fontWeight: 'bold', borderRight: '0.5px solid #c7c7c7', fontSize: '1rem' }}
        >
          {row.description}
        </TableCell>
        {['retroYearAvg', 'retroYearSemiAnnualAvg', 'retroYearQuarterlyAvg', 'retroYearMonthlyAvg'].map((avgKey) => (
          <TableCell
            key={avgKey}
            align="center"
            style={{
              borderRight: '0.5px solid #c7c7c7'
            }}
          >
            <Typography title="Este campo não é editável" variant="body1" sx={{ fontSize: '1rem' }}>
              {parseFloat((cellValues[avgKey] as string).replace('.', ''))}
            </Typography>
          </TableCell>
        ))}
        {renderTextField('january', cellValues.january.toString())}
        {renderTextField('february', cellValues.february.toString())}
        {renderTextField('march', cellValues.march.toString())}
        {renderTextField('april', cellValues.april.toString())}
        {renderTextField('may', cellValues.may.toString())}
        {renderTextField('june', cellValues.june.toString())}
        {renderTextField('july', cellValues.july.toString())}
        {renderTextField('august', cellValues.august.toString())}
        {renderTextField('september', cellValues.september.toString())}
        {renderTextField('october', cellValues.october.toString())}
        {renderTextField('november', cellValues.november.toString())}
        {renderTextField('december', cellValues.december.toString())}
        <TableCell
          align="center"
          style={{
            borderLeft: '0.5px solid #c7c7c7'
          }}
        >
          <Typography title="Este campo não é editável" variant="body1" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
            {accumulatedYear.toFixed(2).replace('.', ',')}
          </Typography>
        </TableCell>
      </TableRow>

      {row.children &&
        row.children.map((childRow) => (
          <TableRowRecursive key={childRow.id} row={childRow} onChildChange={handleChildChange} budget={budget} />
        ))}

      {/* Popover para "Replicar Valores" */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <DialogContent sx={{ p: 0.5 }}>
          <Stack sx={{ display: 'flex', alignItems: 'flex-start' }}>
            {contextInfo?.row?.level == 3 && contextInfo?.column === 'account' && (
              <>
                <Button variant="text" color="primary" size="small" onClick={handleAddNewCostCenter} startIcon={<AddIcon />}>
                  Adicionar novo Centro de Custo
                </Button>
                <Button variant="text" color="primary" size="small" onClick={handleAddCostCenter} startIcon={<FormatListBulletedIcon />}>
                  Adicionar Centro de Custo
                </Button>
              </>
            )}
            {contextInfo?.column !== 'account' && (
              <Button variant="text" color="primary" size="small" onClick={handleReplicateValues} startIcon={<ReplyAllIcon />}>
                Replicar Valor
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Popover>
      {replicateDialog && (
        <BudgetItemReplicateDialog onClose={handleCloseDialogs} item={replicateDialog} onReplicate={replicateValuesToSelectedMonths} />
      )}
      {costCenterDialog && (
        <BudgetItemCostCenterDialog
          onClose={handleCloseDialogs}
          item={costCenterDialog}
          addLineCostCenter={addLineCostCenter}
          company={budget?.company?.id!}
        />
      )}
      {newCostCenterDialog && (
        <BudgetItemCostCenterFormDialog
          onClose={handleCloseDialogs}
          item={newCostCenterDialog}
          addLineCostCenter={addLineCostCenter}
          company={budget?.company?.id!}
        />
      )}
    </>
  );
};

export default TableRowRecursive;
