import { ManualInput } from '../../../../../../models/ManualInput';
import { ManualInputFormDialog } from '../../../../../@manual-inputs/ManualInputsFormDialog';
import { ManualInputsContext } from '../../../../../@manual-inputs/context/ManualInputsContext';

interface Props {
  modalData: any | null;
  onClose: () => void;
}

export const ManualInputModal = ({ modalData, onClose }: Props) => {
  const MODAL_OPEN = modalData ? Object?.keys(modalData)?.length > 0 : false;
  const editItem = {
    entry_date: modalData?.key
  } as ManualInput;

  if (modalData?.row?.origin === 'D') {
    editItem.debit_account = modalData?.row;
  } else {
    editItem.credit_account = modalData?.row;
  }

  return <ManualInputsContext>{MODAL_OPEN && <ManualInputFormDialog editItem={editItem} onClose={onClose} />}</ManualInputsContext>;
};
