import { Close, CloseFullscreen, Minimize, OpenInFull } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';

interface Props {
  open: boolean;
  title: string;
  onClose: () => void;
  onMinimize?: () => void;
  fullscreen?: boolean;
  toggleFullscreen?: () => void;
  children?: React.ReactNode;
  dialogActions?: React.ReactNode;
}

export const DialogWithToggleFullscreen = ({
  open = true,
  title,
  onClose,
  onMinimize,
  fullscreen,
  toggleFullscreen,
  dialogActions,
  children
}: Props) => {
  return (
    <Dialog open={open} onClose={onClose} scroll="paper" maxWidth={false} fullScreen={fullscreen}>
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <DialogTitle variant="h4" color="primary">
          {title}
        </DialogTitle>
        <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
          {onMinimize && (
            <IconButton sx={{ mr: 1 }} color="primary" onClick={onMinimize} size="small">
              <Minimize fontSize="small" />
            </IconButton>
          )}
          {toggleFullscreen && (
            <IconButton sx={{ mr: 1 }} color="primary" onClick={toggleFullscreen} size="small">
              {fullscreen ? <CloseFullscreen fontSize="small" /> : <OpenInFull fontSize="small" />}
            </IconButton>
          )}
          {onClose && (
            <IconButton sx={{ mr: 2 }} color="primary" onClick={onClose} size="small">
              <Close fontSize="small" />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <DialogContent>{children}</DialogContent>
      {dialogActions && dialogActions}
    </Dialog>
  );
};
