import { Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{ display: value == index ? 'flex' : 'none', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}
      {...other}
    >
      <Box sx={{ pt: 2, px: 2, pr: 1 }}>{children}</Box>
    </Box>
  );
};
