import { Box, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import DynamicTable from './TableComponent';
import { useSoldItemAnalyticContext } from './TableComponent/context/SoldItemAnalyticContext';

export const AnalyticSoldItemTab = () => {
  const { fetchSoldItemAnalytic } = useSoldItemAnalyticContext();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchSoldItemAnalytic();
      setLoading(false);
    })();
  }, []);

  if (loading) return null;
  return (
    <Box sx={{ width: '100%' }}>
      <Paper>
        <DynamicTable />
      </Paper>
    </Box>
  );
};
