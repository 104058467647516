import { createContext, useContext, useState } from 'react';

interface AnalyticContextData {
  currentTab: number;
  handleChangeTab: (newValue: number) => void;
}

const AnalyticContext = createContext<AnalyticContextData>({} as AnalyticContextData);

interface Props {
  children: React.ReactNode;
}

export const AnalyticFormContext = ({ children }: Props) => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleChangeTab = (newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <AnalyticContext.Provider
      value={{
        currentTab,
        handleChangeTab
      }}
    >
      {children}
    </AnalyticContext.Provider>
  );
};

export const useAnalyticFormContext = () => {
  const context = useContext(AnalyticContext);
  if (!context) {
    throw new Error('useAnalyticFormContext must be used within an AnalyticFormContext Provider');
  }
  return context;
};
