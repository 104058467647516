import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { IAnalyticsNCGFilter } from '../models/AnalyticsNCG';

const AnalysisNCGUrl = '/reports/ncg';

export const AnalysisNCGService = {
  get: async (filters: IAnalyticsNCGFilter[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(AnalysisNCGUrl, {
        params: filters
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },
  post: async (data: IAnalyticsNCGFilter[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(AnalysisNCGUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
