import { Button, Popover } from '@mui/material';
import { useAnalyticsDRETableContext } from '../../context/TableContext';

interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export const GraphsPopover = ({ anchorEl, onClose }: Props) => {
  const { handleOpenChart } = useAnalyticsDRETableContext();

  const onOpenChart = () => {
    onClose();
    handleOpenChart();
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <>
        <Button variant="contained" color="primary" fullWidth onClick={onOpenChart}>
          Gráfico 1
        </Button>
      </>
    </Popover>
  );
};
