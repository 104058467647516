import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Budget } from '../../../models/Budget';
import { Company } from '../../../models/Company';
import { CompaniesService } from '../../../services/Companies.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { CompaniesFilterFields } from '../../@companies-page/context/CompaniesPageContext';
import { BudgetFilterFields, useBudgetContext } from '../context/BudgetContext';

interface Props {
  onClose: () => void;
  setNumFilters: (numFilters: number) => void;
}

const getDefaultValues = (filter?: Budget): BudgetFilterFields => {
  return {
    reference_year: filter?.reference_year ?? '',
    company: filter?.company
  };
};

export const BudgetFilterDialog = ({ onClose, setNumFilters }: Props) => {
  const dispatch = useDispatch();
  const { filterFields, setFilterFields } = useBudgetContext();
  const [companies, setCompanies] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<BudgetFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const updateNumFilters = (data: BudgetFilterFields) => {
    let count = 0;
    if (data.reference_year) count++;
    if (data.company) count++;
    setNumFilters(count);
  };

  const onSubmit: SubmitHandler<BudgetFilterFields> = async (data: BudgetFilterFields) => {
    data = {
      ...data,
      company: data.company
    };
    setFilterFields({ ...data } as BudgetFilterFields);
    updateNumFilters(data);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as BudgetFilterFields);
    setNumFilters(0);
    onClose();
  };
  const fetchCompanies = async () => {
    try {
      const response = await CompaniesService.get({ is_active: true } as CompaniesFilterFields);
      if (response) {
        const result = response?.data?.results;
        setCompanies(
          result.map((company: Company) => ({
            id: company.id,
            name: company.company_name
          }))
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar empresas',
          severity: 'error'
        })
      );
    }
  };
  useEffect(() => {
    const loadData = async () => {
      dispatch(showSpinner());
      setLoading(true);

      await fetchCompanies();

      setLoading(false);
      dispatch(hideSpinner());
    };

    loadData();
  }, []);

  return (
    <Dialog open={!loading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="company">Empresa</InputLabel>
              <ControlledComboBox
                name="company"
                placeholder="Empresa"
                control={control}
                errorMessage={errors.company?.message?.toString()}
                selectOptions={companies}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="reference_year">Ano</InputLabel>
              <ControlledTextInput
                name="reference_year"
                placeholder="Ano"
                control={control}
                errorMessage={errors.reference_year?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
