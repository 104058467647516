import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { Alert, Autocomplete, Divider, Grid, InputLabel, TextField, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Company } from '../../../models/Company';
import { CompanyGroup } from '../../../models/CompanyGroup';
import { FormMessage } from '../../../models/FormMessage';
import { User } from '../../../models/User';
import { CompaniesService } from '../../../services/Companies.service';
import { CompaniesGroupsService } from '../../../services/CompaniesGroups.service';
import { UsersService } from '../../../services/Users.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { UserScheme } from '../../../utils/forms/validations/formValidations';
import { hexToRgba, setInputErrorsFromApi } from '../../../utils/utils';
import { useUsersPageContext } from '../context/UsersPageContext';

interface CompaniesGroupList {
  group: {
    id: number;
    group_name: string;
  };
  companies: {
    id: number;
    company_name: string;
  }[];
}
interface Props {
  editItem?: User;
  onClose: () => void;
}

export const UsersFormDialog = ({ editItem, onClose }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [companyGroupData, setCompanyGroupData] = useState<CompanyGroup[]>([]);
  const [companiesData, setCompanyData] = useState<Company[]>([]);
  const [userData, setUserData] = useState<User>();
  const [avaiableCompanyGroup, setAvaiableCompanyGroup] = useState<CompanyGroup[]>([]);
  const [companiesGroupList, setCompaniesGroupList] = useState<CompaniesGroupList[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const { fetchUsers } = useUsersPageContext();
  const userId = editItem?.id ? editItem?.id : 0;
  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors }
  } = useForm<User>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(UserScheme),
    defaultValues: editItem ?? {
      username: '',
      email: '',
      password: '',
      confirm_password: '',
      is_active: true,
      is_staff: true,
      first_name: '',
      last_name: '',
      access_all_companies: false
    }
  });

  const fetchUser = async () => {
    try {
      const response = await UsersService.getOne(userId);
      if (response?.data) {
        const dataUser = response?.data;
        setUserData(dataUser);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Erro ao buscar usuário',
          severity: 'error'
        })
      );
    }
  };

  const fetchCompany = async () => {
    try {
      const response = await CompaniesService.getAll();
      if (response?.data?.results) {
        const dataCompanies = response?.data?.results.map((company) => ({ ...company, checked: false }));
        setCompanyData(dataCompanies);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Erro ao buscar Empresas',
          severity: 'error'
        })
      );
    }
  };

  const fetchCompanyGroup = async () => {
    try {
      const response = await CompaniesGroupsService.getAll();
      if (response?.data?.results) {
        const dataCompanyGroup = response?.data?.results.map((item) => ({
          id: item.id,
          group_name: item.name
        }));
        setCompanyGroupData(dataCompanyGroup);
        setAvaiableCompanyGroup(dataCompanyGroup);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Erro ao buscar Grupos de Empresas',
          severity: 'error'
        })
      );
    }
  };

  const handleAddNewLink = () => {
    const newLink = {
      group: {
        id: 0,
        group_name: ''
      },
      companies: []
    };

    setCompaniesGroupList([...companiesGroupList, newLink]);
  };

  const handleCompaniesGroupChange = (event: any, value: any, index: number, companiesGroup: any) => {
    const newCompaniesGroupList = [...companiesGroupList];

    if (value) {
      if (companiesGroup.group.id !== value.id) {
        newCompaniesGroupList[index].companies = [];
      }

      newCompaniesGroupList[index].group.id = value?.id || null;
      newCompaniesGroupList[index].group.group_name = value?.group_name || '';
      setSelectedGroups((prevSelectedGroups) => [...prevSelectedGroups, value.id]);
    } else {
      newCompaniesGroupList[index].group.id = null;
      newCompaniesGroupList[index].group.group_name = '';
      newCompaniesGroupList[index].companies = [];
    }

    setCompaniesGroupList(newCompaniesGroupList);
  };

  const handleCompaniesChange = (event: any, value: any, index: number) => {
    const newCompaniesGroupList = [...companiesGroupList];
    newCompaniesGroupList[index].companies = value.map((item) => ({
      id: item.id,
      company_name: item.company_name
    }));
    setCompaniesGroupList(newCompaniesGroupList);
  };

  const handleDeleteLink = (index: number) => {
    const newCompaniesGroupList = [...companiesGroupList];
    newCompaniesGroupList.splice(Number(index), 1);
    setCompaniesGroupList(newCompaniesGroupList);
  };

  const getCompaniesByGroupId = (groupId: number) => {
    const company = companiesData?.filter((company: any) => company.group.id === groupId);
    return (company || []) as Company[];
  };

  useEffect(() => {
    if (userData) {
      const groupedCompanies = Object.values(
        userData.companies.reduce((acc, company) => {
          const groupId = company.group.id;
          const groupName = company.group.name;

          if (!acc[groupId]) {
            acc[groupId] = {
              group: {
                id: groupId,
                group_name: groupName
              },
              companies: []
            };
          }

          const { id, company_name, trade_name, is_active, type, created_at, updated_at } = company;
          acc[groupId].companies.push({
            id,
            company_name,
            trade_name,
            is_active,
            type,
            created_at,
            updated_at,
            group: {
              id: groupId,
              group_name: groupName
            }
          });

          return acc;
        }, {})
      );
      setCompaniesGroupList(groupedCompanies);
    }
  }, [userData]);

  useEffect(() => {
    (async () => {
      dispatch(showSpinner());
      await fetchCompanyGroup();
      await fetchCompany();
      dispatch(hideSpinner());
    })();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchUser();
    }
  }, [userId]);

  const formValidations = (data: User) => {
    if (!editItem?.id && !data.password) {
      setError('password', {
        type: 'manual',
        message: 'A senha é obrigatória para um novo usuário'
      });
      return false;
    }

    if (data.password && data.password !== data.confirm_password) {
      setError('confirm_password', {
        type: 'manual',
        message: 'As senhas não conferem'
      });
      return false;
    }
    return true;
  };

  const onSubmitForm: SubmitHandler<User> = async (data: User) => {
    if (!formValidations(data)) return;
    let userCompaniesIds;
    if (!data.password && !data.confirm_password) {
      delete data.password;
      delete data.confirm_password;
    }

    if (!editItem?.id) {
      data.is_staff = true;
    } else {
      data.is_staff = editItem.is_staff;
    }

    if (data.access_all_companies) {
      userCompaniesIds = companiesData.map((item) => item.id);
    } else {
      userCompaniesIds = companiesGroupList.flatMap((group) => group.companies.map((company) => company.id));
    }

    const payload = { ...data, companies: userCompaniesIds };
    delete payload.profiles;
    delete payload.permissions;

    dispatch(showSpinner());
    try {
      const response = editItem?.id ? await UsersService.update(payload) : await UsersService.create(payload);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Usuário atualizado com sucesso!' : 'Usuário criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchUsers();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data)?.join(',') || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };
  const watchStatus = useWatch({
    control,
    name: 'is_active',
    defaultValue: getValues('is_active')
  });

  const watchLinkCompanies = useWatch({
    control,
    name: 'access_all_companies',
    defaultValue: getValues('access_all_companies')
  });

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Usuário
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {formMessage?.message && (
                <Grid item xs={12}>
                  <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                    {formMessage.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="username">Usuário</InputLabel>
              <ControlledTextInput
                autoComplete="none"
                name="username"
                placeholder="Usuário"
                control={control}
                errorMessage={errors.username?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="email">E-mail</InputLabel>
              <ControlledTextInput
                name="email"
                placeholder="E-mail"
                type="email"
                control={control}
                errorMessage={errors.email?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="first_name">Nome</InputLabel>
              <ControlledTextInput
                name="first_name"
                placeholder="Nome"
                control={control}
                errorMessage={errors.first_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="last_name">Sobrenome</InputLabel>
              <ControlledTextInput
                name="last_name"
                placeholder="Sobrenome"
                control={control}
                errorMessage={errors.last_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="password">Nova Senha</InputLabel>
              <ControlledTextInput
                autoComplete="new-password"
                name="password"
                placeholder="Nova Senha"
                type="password"
                control={control}
                errorMessage={errors.password?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="confirm_password">Confirmar Senha</InputLabel>
              <ControlledTextInput
                name="confirm_password"
                placeholder="Confirmar Senha"
                type="password"
                control={control}
                errorMessage={errors.confirm_password?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body1">Situação:</Typography>
              <ControlledCheckbox name="is_active" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>
            <Grid container item xs={12} sm={6} alignItems="center">
              <Typography variant="h5" component="div" color="primary">
                Vínculos de Empresas:
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={6} alignItems="center">
              <ControlledCheckbox name="access_all_companies" label={'Todas Empresas'} control={control} />
            </Grid>

            {!watchLinkCompanies && (
              <>
                {companiesGroupList?.map((companiesGroup, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <Grid container key={index} spacing={2} marginBottom={'10px'}>
                        <Grid item xs={12} sm={6}>
                          <InputLabel htmlFor="companies_group">Selecione o Grupo de Empresa</InputLabel>
                          <Autocomplete
                            key={index + companiesGroup.group.id}
                            size="small"
                            options={companyGroupData.filter((group) => !selectedGroups.includes(group.id))}
                            getOptionLabel={(option) => String(option.group_name)}
                            onChange={(event, value) => handleCompaniesGroupChange(event, value, index, companiesGroup)}
                            renderInput={(params) => <TextField {...params} size="small" label="" />}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            defaultValue={companiesGroup.group}
                            noOptionsText="Nenhum grupo disponível"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputLabel htmlFor="companies">Selecione a(s) Empresa(s)</InputLabel>
                          <Autocomplete
                            multiple
                            filterSelectedOptions
                            key={index}
                            options={getCompaniesByGroupId(Number(companiesGroup.group.id))}
                            getOptionLabel={(option) => String(option.company_name)}
                            onChange={(event, value) => handleCompaniesChange(event, value, index)}
                            renderInput={(params) => <TextField {...params} size="small" label="" />}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={companiesGroup.companies}
                            noOptionsText="Nenhuma empresa disponível"
                          ></Autocomplete>
                        </Grid>
                      </Grid>
                      {Boolean(index == companiesGroupList.length - 1) && companiesGroupList.length > 1 && (
                        <Grid
                          item
                          sm={12}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderColor: hexToRgba(theme.palette.primary.main, 0.5),
                            divider: 'true',
                            mt: 2,
                            ml: 2
                          }}
                        >
                          <Button
                            variant="text"
                            color="primary"
                            startIcon={<CloseIcon />}
                            onClick={() => handleDeleteLink(index)}
                            sx={{ mt: -1 }}
                          >
                            Excluir Vínculo
                          </Button>
                        </Grid>
                      )}
                      <Divider />
                    </Grid>
                  );
                })}

                {avaiableCompanyGroup.length > 0 && (
                  <Grid item sm={12} sx={{ mt: -1 }}>
                    <InputLabel sx={{ mb: 1 }}>Adicionar novo vínculo</InputLabel>
                    <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleAddNewLink}>
                      Adicionar
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
