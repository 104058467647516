import { Button, Stack } from '@mui/material';

interface Props {
  currentValue: number[];
  onChange: (value: number[]) => void;
}

export const TabQuarterSelector = ({ currentValue, onChange }: Props) => {
  const handleChange = (value: number) => {
    if (currentValue.includes(value)) {
      onChange(currentValue.filter((quarter) => quarter !== value));
    } else {
      onChange([...currentValue, value]);
    }
  };

  return (
    <Stack sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: 2 }}>
      {Array.from({ length: 4 }, (_, i) => (
        <Button
          key={i + 1}
          variant={currentValue.includes(i + 1) ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleChange(i + 1)}
        >
          {i + 1}º Trimestre
        </Button>
      ))}
    </Stack>
  );
};
