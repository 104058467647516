// assets
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const AnalyticsRoutes: INavGroup = {
  id: 'group-analytics',
  title: 'Análises',
  icon: <BarChartIcon />,
  type: 'group',
  module: 'ANALYTICS',
  level: 1,
  children: [
    {
      id: 'AnalyticType',
      title: 'Tipo de Análise',
      type: 'item',
      url: ROUTES.authenticated.analytics.analyticType,
      icon: <AnalyticsOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'ANALYTICS'
    },
    {
      id: 'ChartAccount',
      title: 'Plano de Contas',
      type: 'item',
      url: ROUTES.authenticated.analytics.chartAccount,
      icon: <CardTravelOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'ANALYTICS'
    },
    {
      id: 'analytics',
      title: 'Análises',
      type: 'item',
      url: ROUTES.authenticated.analytics.analytics,
      icon: <ShowChartIcon />,
      breadcrumbs: false,
      submodule: 'ANALYTICS'
    }
  ]
};
