import { DialogWithToggleFullscreen } from '../../../../../../components/basics/DialogWithToggleFullscreen';
import { IAnalysisNcgModal } from '../context/TableContext';
import { GraphComponentNcg } from './GraphComponentNcg';

interface Props {
  modalData: IAnalysisNcgModal;
  onClose: () => void;
  onMinimize: () => void;
  onToggleModalFullscreen: () => void;
}

export const ChartModal = ({ modalData, onClose, onMinimize, onToggleModalFullscreen }: Props) => {
  return (
    <DialogWithToggleFullscreen
      open={!!modalData}
      onClose={onClose}
      title="Gráfico"
      onMinimize={onMinimize}
      fullscreen={modalData.fullscreen}
      toggleFullscreen={onToggleModalFullscreen}
    >
      <GraphComponentNcg />
    </DialogWithToggleFullscreen>
  );
};
