import { Box, Divider, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TabMonthRangeSelector } from '../TabMonthRangeSelector';
import { TabPanel } from '../TabPanel';
import { TabQuarterSelector } from '../TabQuarterSelector';
import { TabSemesterSelector } from '../TabSemesterSelector';
import { YearSelectorComponent } from '../YearSelectorComponent';

interface Props {
  yearsSelectionQuantity: number;
  periodSelectorTab: number;
  setPeriodSelectorTab: (value: number) => void;
}

export const PeriodSelectorTabs = forwardRef(({ yearsSelectionQuantity, periodSelectorTab, setPeriodSelectorTab }: Props, ref: any) => {
  const dispatch = useDispatch();
  const [currentSemester, setCurrentSemester] = useState<number[]>([]);
  const [currentQuarter, setCurrentQuarter] = useState<number[]>([]);

  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs());

  const [type, setType] = useState<''>('');
  const [period, setPeriod] = useState<[]>([]);
  const [years, setYears] = useState<[]>([]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setPeriodSelectorTab(newValue);
  };

  const onSubmit = () => {
    if (periodSelectorTab === 0) {
      return {
        years: years,
        period: null,
        type: 'yearly'
      };
    }

    // seletor semestral
    if (periodSelectorTab === 1) {
      if (currentSemester?.length > 0) {
        return {
          years: years,
          period: currentSemester,
          type: 'semi-annual'
        };
      }
      return {
        years: years,
        period: null,
        type: 'semi-annual'
      };
    }

    // seletor trimestral
    if (periodSelectorTab === 2) {
      if (currentQuarter?.length > 0) {
        return {
          years: years,
          period: currentQuarter,
          type: 'quarterly'
        };
      }
      return {
        years: years,
        period: null,
        type: 'quarterly'
      };
    }

    // intervalo de meses
    if (periodSelectorTab === 3) {
      if (!startDate)
        return {
          years: years,
          period: null,
          type: 'monthly'
        };

      if (!endDate)
        return {
          years: years,
          period: [startDate && startDate?.month() + 1],
          type: 'monthly'
        };

      const startMonth = startDate.month() + 1;
      const endMonth = endDate.month() + 1;
      const periodMonthly = [];

      for (let month = startMonth; month <= endMonth; month++) {
        periodMonthly.push(month);
      }

      return {
        years: years,
        period: periodMonthly,
        type: 'monthly'
      };
    }

    return {
      years: null,
      period: null,
      type: 'monthly'
    };
  };

  const clear = () => {
    setYears([]);
    setType('');
    setPeriod([]);
    setStartDate(dayjs());
    setEndDate(dayjs());
    setCurrentSemester([]);
    setCurrentQuarter([]);
  };

  useImperativeHandle(ref, () => ({
    onSubmit,
    clear
  }));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center', margin: 2 }}>
        <Tooltip title="Clique no ano que deseja visualizar.">
          <Typography variant="subtitle1" sx={{ textDecoration: 'underline' }} color="secondary">
            Ano
          </Typography>
        </Tooltip>
        <YearSelectorComponent selectionQuantity={yearsSelectionQuantity} year={years} setYear={setYears} />
      </Box>
      <Box sx={{ display: 'flex', flex: 1 }}>
        <Divider orientation="vertical" />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center', margin: 2 }}>
        <Tabs
          orientation="vertical"
          value={periodSelectorTab}
          onChange={handleChangeTab}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            mt: 4
          }}
        >
          <Tab label="Anual" />
          <Tab label="Semestral" />
          <Tab label="Trimestral" />
          <Tab label="Intervalo Meses" />
        </Tabs>
      </Box>
      <TabPanel value={periodSelectorTab} index={0}>
        <Typography variant="h6" color="secondary">
          Todos os meses do ano selecionado.
        </Typography>
      </TabPanel>
      <TabPanel value={periodSelectorTab} index={1}>
        <TabSemesterSelector currentValue={currentSemester} onChange={setCurrentSemester} />
      </TabPanel>
      <TabPanel value={periodSelectorTab} index={2}>
        <TabQuarterSelector currentValue={currentQuarter} onChange={setCurrentQuarter} />
      </TabPanel>
      <TabPanel value={periodSelectorTab} index={3}>
        <TabMonthRangeSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
      </TabPanel>
    </Box>
  );
});
