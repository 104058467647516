import AddIcon from '@mui/icons-material/Add';
import { Button, Divider, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DialogWithToggleFullscreen } from '../../../../../components/basics/DialogWithToggleFullscreen';
import { useCrudPermissions } from '../../../../../hooks/useCrudPermissions';
import { ManualInput } from '../../../../../models/ManualInput';
import { AnalyticsAccountService } from '../../../../../services/AnalyticsAccount.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../utils/api/response';
import { getStartAndFinalDate } from '../../../../../utils/utils';
import { ButtonsContainer } from '../../../../@analytics-type/AnalyticsTypePage/styles';
import { ManualInputsFilterFields, useManualInputsContext } from '../../../../@manual-inputs/context/ManualInputsContext';
import { ManualInputDetailsDialog } from '../../../../@manual-inputs/ManualInputsDetailsDialog';
import { ManualInputFormDialog } from '../../../../@manual-inputs/ManualInputsFormDialog';
import { ManualInputsTable } from '../../../../@manual-inputs/ManualInputsTableCard/ManualInputsTable';
import { IAnalysisBpModal } from '../../../TabsContent/AnalyticBP/DynamicTableBP/context/TableContext';

interface Props {
  modalData: IAnalysisBpModal;
  onClose: () => void;
  onMinimize: () => void;
  onToggleModalFullscreen: () => void;
}

export const ManualInputsTableModal = ({ modalData, onClose, onMinimize, onToggleModalFullscreen }: Props) => {
  const { permissions } = useCrudPermissions({ submodule: 'MANUAL_ACCOUNTING_ENTRY' });
  const { setFilterFields, manualInputs } = useManualInputsContext();
  const [newItem, setNewItem] = useState(false);
  const [editItem, setEditItem] = useState<ManualInput | undefined>(undefined);
  const [viewItem, setViewItem] = useState<ManualInput | undefined>(undefined);

  const dispatch = useDispatch();
  let account_id = modalData?.data?.row?.id;
  let entry_date = modalData?.data?.key;
  const account_description = modalData?.data?.row?.description;
  const { startDate, finalDate } = getStartAndFinalDate(entry_date);
  const handleAddManualInput = async () => {
    const accountData = await getAccount();
    setNewItem(true);

    const accountCode = accountData?.code;
    const accountDetails = {
      ...modalData?.data?.row,
      code: accountCode
    };

    const item: ManualInput = {
      entry_date,
      ...(modalData?.data?.row?.origin === 'D' ? { debit_account: accountDetails } : { credit_account: accountDetails })
    };

    setEditItem(item);
  };

  const getAccount = async () => {
    dispatch(showSpinner());
    try {
      const response = await AnalyticsAccountService.getOne(account_id);
      if (response && checkResponseStatus(response)) {
        const results = response.data;
        return results;
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error?.data?.detail || 'Houve um erro ao buscar informações da conta',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
    return null;
  };

  const handleViewItem = (item: ManualInput) => {
    setViewItem(item);
  };

  const handleEditItem = (item: ManualInput) => {
    setEditItem(item);
  };

  const handleCloseDialogs = () => {
    setNewItem(false);
    setEditItem(undefined);
    setViewItem(undefined);
  };

  useEffect(() => {
    if (startDate && finalDate && account_id) {
      setFilterFields({ entry_date_after: startDate, entry_date_before: finalDate, account_id: account_id } as ManualInputsFilterFields);
    }
  }, [startDate, finalDate, account_id]);

  return (
    <DialogWithToggleFullscreen
      open={!!manualInputs}
      onClose={onClose}
      title={`Lançamentos Manuais - ${account_description}`}
      onMinimize={onMinimize}
      fullscreen={modalData.fullscreen}
      toggleFullscreen={onToggleModalFullscreen}
    >
      <Divider />
      <ButtonsContainer>
        {permissions.hasCreatePermission && (
          <Tooltip title="Cadastrar Lançamento Manual">
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddManualInput}>
              Adicionar
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      <ManualInputsTable onView={handleViewItem} onEdit={handleEditItem} permissions={permissions} ManualInputs={manualInputs} />
      {viewItem && <ManualInputDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
      {(newItem || editItem) && <ManualInputFormDialog editItem={editItem} onClose={handleCloseDialogs} />}
    </DialogWithToggleFullscreen>
  );
};
